import styled from "styled-components";

export const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-items: flex-start;
  border-bottom: 1px solid #eaeaea;
  text-align: left;
  @media (max-width: 576px) {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10px;
  }
  label {
    text-transform: capitalize;
    font-weight:600;
  }
`;

export const CountryCodeSelect = styled.div`
  height: auto;
  max-width: 8rem;
 
  background: #f0f0f0;
  color: #6c757d;;
  border: 1px solid rgb(234, 234, 234);
  padding: 0 0.75rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 2rem;
    height: 1rem;
    // aspect-ratio: 16/ 9;
    object-fit: cover;
  }

  span {
    margin-right: .5rem;
  }

  &:hover {
    cursor: pointer;
  }

  @media(max-width:762px){
    // padding: 0.62rem 0.75rem;
    font-size:.7rem;
    img{
      width:60%;
    }
  }
`;

export const ContactInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  // grid-gap: 20px;
  align-items: center;
  label {
    font-weight:400;
  }
  .input-group-text {
    padding: 0;
    border-right: 1px solid #eee;
  }
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 4%;
    align-items: center;
  }
`;
export const Select = styled.select`
  padding: 0.575rem 1.2rem .575rem .5rem;
  border: none;
  border: 1px solid #eaeaea;
  border-right: 0px solid transparent;
  outline: none;
  -webkit-appearance: none;
  background :url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") 95% no-repeat;
  background-size: 12px;
  border-radius:0;
  &&:focus-visible {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
  @media (max-width:768px){
    background-size: 10px;
  }
`;
export const ContactText = styled.div`
  padding-bottom: 20px;
  font-size: 12px;
  text-align: center;
`;

export const UserContactWrapper = styled.div`
  display: flex;
  Select {
    width: 40%;
  }
`;
export const LoginWrapper = styled.div`
  text-transform: capitalize;
  display: flex;
  margin-bottom: 20px;
`;
export const LoginText = styled.div`
  ${(props) => ` 
 color: ${props.textcolor};
  transition: 0.4s;
  &&:hover {
    color: ${props.texthvcolor};
  }
  cursor: pointer;
  margin-left: 10px;
`}
`;
export const ContentInputs = styled.div`
display:grid;
grid-template-columns:1fr 1fr;
gap:2.5rem;
@media (max-width: 768px) {
    grid-template-columns: 100%;
    align-items: center;
    gap:10px;
  }
`
export const InputLabel = styled.div`
// margin-bottom:10px;
`
