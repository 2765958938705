import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// import Select from "react-select";
import { toast } from "react-toastify";
// import exchangePhoto from "../../../assets/images/exchange.svg";
import { dateForm, getDateTwoDaysLater, getDropLocation, getPicLocation, getQueryParamValue } from "../../../helpers.js";
// import {
//   addBusName,
//   addError,
//   clearSavedBookIds,
//   isLoading,
//   regularBookingInformation,
//   SearchInfoStore,
//   updateFareSummery,
// } from "../../../redux/action/busAction.js";
import {
  BookingPageDate,
  BookingPageExchangePhoto,
  BookingPageInnerForm,
  BookingPageJourneyDate,
  BookingPageLocation,
  BookingPageReturnDate,
  BookingPageSearchBtn,
  Calendar,
  DateInput,
  DateText,
  ExchangePhoto,
  Form,
  InnerForm,
  JourneyDate,
  Location,
  ReturnDate,
  SearchBtn,
  SearchBtnArea,
  SearchLocation,
  BookingPageExchangePhotoTwo,
  SearchBtnAreaFindTrip,
  LocationWrapper,
  LocationUl,
  RadioInput,
  SinglePayment,
  PaymentInput,
  Label,
} from "./SearchFrom.styles.js";
import jq from "jquery";

const SearchForm = ({ locationRef, searchInfo }) => {
  const currentURL = useLocation();
  // const queryParams = new URLSearchParams(currentURL.search);
  const { webSettingData, languageData, isLoadings } = useSelector(
    (state) => state.busLists
  );
  const [location, setLocation] = useState([]);
  const [drpBus, setDrpBus] = useState([]);
  const [selectWay, setSelectWay] = useState("1");

  const departRef = useRef(null);
  const returnRef = useRef(null);

  const history = useHistory();
  const isHome = history.location.pathname !== "/";
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchInfo?.journeydate) {
      setJournyStartDate(
        new window.Date(searchInfo?.journeydate.replace(/-/g, "/"))
      );
    }

    // if (searchInfo?.returnDate && searchInfo.returnDate != "NaN-NaN-NaN") {
    //   setJournyReturnDate(
    //     new window.Date(searchInfo?.returnDate.replace(/-/g, "/"))
    //   );
    // }
  }, [searchInfo]);

  const cursorStyles = {
    option: (styles) => {
      return {
        ...styles,
        cursor: "pointer",
      };
    },
  };

  let dropLocation = searchInfo?.dropLocation || "";
  let pickLocation = searchInfo?.pickLocation || "";
  let journeyDate = new window.Date();
  let returnJourneyDate = new window.Date();;

  if (window.location.search.length > 0) {
    dropLocation = getQueryParamValue("drop");
    pickLocation = getQueryParamValue("pickup");

    const date =  getQueryParamValue("date");
    journeyDate = date ? parseDate(date) : new window.Date();

    const returnDate =  getQueryParamValue("return-date");
    returnJourneyDate = returnDate ? parseDate(returnDate) : "";

    function parseDate(dateString) {
      const [year, month, day] = dateString.split('-').map(Number);
    
      // Ensure the month has two digits
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;
    
      return new Date(`${year}-${formattedMonth}-${formattedDay}`);
    }
  }

  const [values, setValues] = useState({
    drop_location: dropLocation,
    pick_location: pickLocation,
  });
  const [journyStartDate, setJournyStartDate] = useState(journeyDate);
  const [journyReturnDate, setJournyReturnDate] = useState(returnJourneyDate);
  useEffect(() => {
    setValues({
      ...values,
      drop_location: dropLocation,
      pick_location: pickLocation,
    });
  }, [searchInfo, currentURL]);

  const handleBoardingChange = (selectOption) => {
    setValues({ ...values, pick_location: selectOption.id });
    if (selectOption.id === values?.drop_location) {
      !toast.isActive("sameLocation") &&
        toast.error("Boarding point and destination must not be the same", {
          toastId: "sameLocation",
        });
    }
  };

  const formatDate = (dates) => {
    const date = new Date(dates);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const handleDroppingChange = (selectOption) => {
    setValues({ ...values, drop_location: selectOption.id });
    if (selectOption.id === values?.pick_location) {
      !toast.isActive("sameLocation") &&
        toast.error("Boarding point and destination must not be the same", {
          toastId: "sameLocation",
        });
    }
  };

  let locations = [];
  location?.map((item) =>
    locations.push({
      value: item?.name,
      label: item?.name,
      id: item?.id,
    })
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Remove all old localstoreage data
  //   dispatch(regularBookingInformation(" "));
  //   dispatch(updateFareSummery(null));
  //   // clear the bookingIds array from redux
  //   dispatch(clearSavedBookIds());
  //   localStorage.removeItem("bookingInfo");
  //   localStorage.removeItem("searchInfo");
  //   localStorage.removeItem("returnFirstJourneyInfo");
  //   localStorage.removeItem("journeyInfo");

  //   localStorage.removeItem("discount");
  //   localStorage.removeItem("regular");
  //   localStorage.removeItem("return");
  //   localStorage.removeItem("bookingInfoTax");
  //   localStorage.removeItem("journeyInfoTax");
  //   localStorage.removeItem("subtripId");
  //   localStorage.removeItem("passengerInformation");
  //   dispatch(addError(""));

  //   // localStorage.clear();

  //   const formData = new FormData();
  //   const pickLocation = location.find(
  //     (item) => item.id === values.pick_location
  //   );
  //   const dropLocation = location.find(
  //     (item) => item.id === values.drop_location
  //   );
  //   const toastId = "searchTrip";
  //   if (!pickLocation?.id && !dropLocation?.id) {
  //     !toast.isActive(toastId) &&
  //       toast.error("Please select your pick up and drop location.", {
  //         toastId: toastId,
  //       });
  //     return;
  //   } else if (pickLocation?.id === dropLocation?.id) {
  //     !toast.isActive("sameLocation") &&
  //       toast.error("Boarding point and destination must not be the same", {
  //         toastId: "sameLocation",
  //       });
  //     return;
  //   } else if (!pickLocation?.id) {
  //     !toast.isActive("pickup") &&
  //       toast.error("Please select your pick up point", { toastId: "pickup" });
  //     return;
  //   } else if (!dropLocation?.id) {
  //     !toast.isActive("drop") &&
  //       toast.error("Please select your destination", { toastId: "drop" });
  //     return;
  //   } else if (!journyStartDate) {
  //     !toast.isActive("journyDate") &&
  //       toast.error("Please select your journey date", {
  //         toastId: "journyDate",
  //       });
  //     return;
  //   }

  //   formData.append("pick_location_id", pickLocation?.id);
  //   formData.append("drop_location_id", dropLocation?.id);
  //   formData.append("journeydate", dateForm(journyStartDate));

  //   const searchInfo = {
  //     pickLocation: pickLocation?.id,
  //     dropLocation: dropLocation?.id,
  //     journeydate: dateForm(journyStartDate),
  //     returnDate: dateForm(journyReturnDate),
  //   };
  //   dispatch(SearchInfoStore(searchInfo));
  //   localStorage.setItem("searchInfo", JSON.stringify(searchInfo));

  //   function parseDateString(dateString) {
  //     const [year, month, day] = dateString.split("-").map(Number);
  //     return new Date(year, month - 1, day);
  //   }

  //   const journeydate = parseDateString(searchInfo?.journeydate);
  //   const returnDate = parseDateString(searchInfo?.returnDate);

  //   if (journeydate > returnDate) {
  //     !toast.isActive("invalidReturnDate") &&
  //       toast.error("Invalid journey date & return date.", {
  //         toastId: "invalidReturnDate",
  //       });
  //     return;
  //   }
  //   if (searchInfo?.journeydate === searchInfo?.returnDate) {
  //     !toast.isActive("sameDate") &&
  //       toast.error("Journey date & return date can't be same.", {
  //         toastId: "sameDate",
  //       });
  //     return;
  //   }
  //   dispatch(isLoading(true));
  //   const response = await fetch(
  //     `${process.env.REACT_APP_API_MODULE_DOMAIN}/triplist`,
  //     {
  //       method: "POST",
  //       body: formData,
  //     }
  //   );
  //   const result = await response.json();

  //   if (result.status === "success") {
  //     dispatch(addBusName(result.data));
  //     dispatch(isLoading(false));
  //     dispatch(addError(""));
  //     history.push("/booking");
  //   } else {
  //     dispatch(addBusName(null));
  //     dispatch(isLoading(false));
  //     dispatch(addError(result?.message));
  //     toast.error(`${result?.message}`);
  //   }
  // };

  // const locationData = async () => {
  //   const response = await fetch(
  //     `${process.env.REACT_APP_API_MODULE_DOMAIN}/locations`
  //   );
  //   const result = await response.json();
  //   setLocation(result.data);
  };

  // useEffect(() => {
    
  //   try {
  //     locationData();
  //     return () => {
  //       setLocation({});
  //     };
  //   } catch (error) {
  //     console.log("searchForm error", error);
  //   }
  // }, []);
  const handleSwap = () => {
    const pickup = values.drop_location;
    const drop = values.pick_location;
    setValues({ ...values, drop_location: drop, pick_location: pickup });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://operatorapi.busonlineticket.com/api_service.aspx/GetOperatorLocation",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              profile: "transtar_site_new",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setDrpBus(JSON.parse(data.d));
      } catch (error) {
        console.log("Error fetching data: " + error.message);
      }
    };

    fetchData();
  }, []);

  var _ticketType = "bus";

  function fetchFrom(fromVal) {
    
    jq("#divSearchResultTo").hide();
    var _code = "";
    if (fromVal == "") {
      for (var i = 0; i < drpBus.length; i++) {
        _code +=
          '<li class="select2-results-dept-0 select2-result select2-result-unselectable select2-result-with-children"><div class="select2-result-label"><span class="select2-match"></span><b>' +
          drpBus[i].stateFrom +
          '</b></div><ul class="select2-result-sub">';
        for (var j = 0; j < drpBus[i].mapFrom.length; j++) {
          var sp = drpBus[i].mapFrom[j].mFrom.split("|");
          var mFrom = sp[0];
          var ff = "";
          if (sp.length > 1) {
            ff = sp[1];
          }
          _code +=
            '<li class="select2-results-dept-1 select2-result select2-result-selectable li-select-from"><div class="select2-result-label" style="cursor:pointer;"><span class="select2-match"></span>' +
            mFrom +
            '<input type="hidden" class="hdnLocFilter" value="' +
            ff +
            '" /> </div></li>';
        }
        _code += "</ul></li>";
      }
    } else {
      for (var i = 0; i < drpBus.length; i++) {
        var temp = i;
        for (var j = 0; j < drpBus[i].mapFrom.length; j++) {
          if (
            drpBus[i].stateFrom.search(new RegExp(fromVal, "i")) > -1 ||
            drpBus[i].mapFrom[j].mFrom.search(new RegExp(fromVal, "i")) > -1
          ) {
            if (i == temp) {
              _code +=
                '<li class="select2-results-dept-0 select2-result select2-result-unselectable select2-result-with-children"><div class="select2-result-label"><span class="select2-match"></span><b>' +
                drpBus[i].stateFrom +
                '</b></div><ul class="select2-result-sub">';
              temp++;
            }
            var sp = drpBus[i].mapFrom[j].mFrom.split("|");
            var mFrom = sp[0];
            var ff = "";
            if (sp.length > 1) {
              ff = sp[1];
            }
            _code +=
              '<li class="select2-results-dept-1 select2-result select2-result-selectable li-select-from"><div class="select2-result-label"><span class="select2-match"></span>' +
              mFrom +
              '<input type="hidden" class="hdnLocFilter" value="' +
              ff +
              '" /> </div></li>';
          }
        }
        _code += "</ul></li>";
      }
    }
    _code += '</ul></div><div class="clearfix"></div>';
    jq("#divSearchResultFrom ul").empty();
    jq("#divSearchResultFrom ul").html(_code);
    jq("#divSearchResultFrom").show();
    jq(".li-select-from").on("click", function () {
      addtoText("from", this, "bus");
    });
  }
  function fetchTo(from, fromState, toVal) {
    jq("#divSearchResultFrom").hide();
    var _code = "";
    if (toVal == "") {
      for (var i = 0; i < drpBus.length; i++) {
        if (
          jq.trim(drpBus[i].stateFrom.replace("-", "").replace("-", "")) ==
          fromState
        ) {
          for (var j = 0; j < drpBus[i].mapFrom.length; j++) {
            if (drpBus[i].mapFrom[j].mFrom == from) {
              for (var k = 0; k < drpBus[i].mapFrom[j].stateTo.length; k++) {
                _code +=
                  '<li class="select2-results-dept-0 select2-result select2-result-unselectable select2-result-with-children"><div class="select2-result-label"><span class="select2-match"></span><b>' +
                  drpBus[i].mapFrom[j].stateTo[k].sTo +
                  '</b></div><ul class="select2-result-sub">';
                for (
                  var l = 0;
                  l < drpBus[i].mapFrom[j].stateTo[k].mapTo.length;
                  l++
                ) {
                  var sp =
                    drpBus[i].mapFrom[j].stateTo[k].mapTo[l].mTo.split("|");
                  var mTo = sp[0];
                  var ff = "";
                  if (sp.length > 1) {
                    ff = sp[1];
                  }
                  _code +=
                    '<li class="select2-results-dept-1 select2-result select2-result-selectable li-select-to"><div class="select2-result-label" style="cursor:pointer;"><span class="select2-match"></span>' +
                    mTo +
                    '<input type="hidden" class="hdnLocFilter" value="' +
                    ff +
                    '" /> </div></li>';
                }
                _code += "</ul></li>";
              }
            }
          }
        }
      }
    } else {
      for (var i = 0; i < drpBus.length; i++) {
        if (
          jq.trim(drpBus[i].stateFrom.replace("-", "").replace("-", "")) ==
          fromState
        ) {
          for (var j = 0; j < drpBus[i].mapFrom.length; j++) {
            if (drpBus[i].mapFrom[j].mFrom == from) {
              for (var k = 0; k < drpBus[i].mapFrom[j].stateTo.length; k++) {
                var temp = k;
                for (
                  var l = 0;
                  l < drpBus[i].mapFrom[j].stateTo[k].mapTo.length;
                  l++
                ) {
                  if (
                    drpBus[i].mapFrom[j].stateTo[k].sTo.search(
                      new RegExp(toVal, "i")
                    ) > -1 ||
                    drpBus[i].mapFrom[j].stateTo[k].mapTo[l].mTo.search(
                      new RegExp(toVal, "i")
                    ) > -1
                  ) {
                    if (k == temp) {
                      _code +=
                        '<li class="select2-results-dept-0 select2-result select2-result-unselectable select2-result-with-children"><div class="select2-result-label"><span class="select2-match"></span><b>' +
                        drpBus[i].mapFrom[j].stateTo[k].sTo +
                        '</b></div><ul class="select2-result-sub">';
                      temp++;
                    }
                    var sp =
                      drpBus[i].mapFrom[j].stateTo[k].mapTo[l].mTo.split("|");
                    var mTo = sp[0];
                    var ff = "";
                    if (sp.length > 1) {
                      ff = sp[1];
                    }
                    _code +=
                      '<li class="select2-results-dept-1 select2-result select2-result-selectable li-select-to"><div class="select2-result-label"><span class="select2-match"></span>' +
                      mTo +
                      '<input type="hidden" class="hdnLocFilter" value="' +
                      ff +
                      '" /> </div></li>';
                  }
                }
                _code += "</ul></li>";
              }
            }
          }
        }
      }
    }
    _code += '</ul></div><div class="clearfix"></div>';
    jq("#divSearchResultTo ul").empty();
    jq("#divSearchResultTo ul").html(_code);
    jq("#divSearchResultTo").show();
    jq(".li-select-to").on("click", function () {
      addtoText("to", this, "bus");
    });
  }
  function addtoText(type, elem, ticketType) {
    var fromState = jq.trim(
      jq(elem)
        .closest(".select2-result-unselectable")
        .find(".select2-result-label:first")
        .text()
        .replace("-", "")
        .replace("-", "")
    );
    if (type == "from") {
      if (ticketType == "bus") {
        jq("[id$='txtOrigin']").val(jq(elem).text());
        jq("[id$='txtOrigin']").attr("fromState", fromState);
        jq("[id$='txtOrigin']").attr(
          "ddFromFilter",
          jq(elem).find(".hdnLocFilter").val()
        );
        jq("#divSearchResultFrom ul").empty();
        jq("#divSearchResultFrom").hide();
      }
    } else if (type == "to") {
      if (ticketType == "bus") {
        jq("[id$='txtDestination']").val(jq(elem).text());
        jq("[id$='txtDestination']").attr(
          "ddToFilter",
          jq(elem).find(".hdnLocFilter").val()
        );
        jq("#divSearchResultTo ul").empty();
        jq("#divSearchResultTo").hide();
        var oneway = null;
        if (jq("#rb1").is(":visible")) {
          oneway = jq("#rb1");
        } else {
          oneway = jq("#rb1");
        }
        var way = "1";
        if (oneway.is(":checked")) way = "1";
        else way = "2";
        var qty = jq("#qty").val();
        if (jq("#pax").is(":visible")) {
          qty = jq("#pax").val();
        }
      }
    }
  }

  

  //----------- Search Bus
  jq("#btnBusSearch")
    .unbind()
    .click(function () {
      if (jq("#txtOrigin").val() == "") {
        toast.error("Please select departure point");
        // alert("Please select departure point");
        return;
      }
    if (jq('#txtDestination').val() == "") { toast.error("Please select arrival point"); return; }
    // var oneway = jq("#rb1");
    // var pax = jq("#qty").val();
    var oneway = 1;
    var pax = 1;

    var ddFromFilter = jq("[id$='txtOrigin']").attr("ddFromFilter");
    var ddtofilter = jq("[id$='txtDestination']").attr("ddtofilter");
    if (ddFromFilter != "") {
        ddFromFilter = "&ddFromFilter=" + ddFromFilter;
    } else {
        ddFromFilter = "";
    }
    if (ddtofilter != "") {
        ddtofilter = "&ddtofilter=" + ddtofilter;
    } else {
        ddtofilter = "";
    }
    var sbf = jq("[id$='txtOrigin']").attr("fromState");
    var returnDate = selectWay == '1' ? formatDate(journyStartDate) == formatDate(new window.Date()) ? formatDate(journyStartDate) :  getDateTwoDaysLater(journyStartDate) : formatDate(journyReturnDate);
    var booking_url = '/booking-new/?' + "ddCurrency=" + encodeURIComponent(localStorage.getItem("currency")) + "&ddFrom=" + jq.trim(jq("[id$='txtOrigin']").val()) + "&ddTo=" + jq.trim(jq("[id$='txtDestination']").val()) + "&deptdate=" + formatDate(journyStartDate) + "&rtndate=" + returnDate;
    booking_url += "&pax=" + pax;
       selectWay == '1' ? booking_url += "&way=1" : booking_url += "&way=2";
    
    window.location.href = booking_url + ddFromFilter + ddtofilter + "&type=Bus&sbf=" + sbf;
    // window.location.href = booking_url + "&type=Bus&sbf=" + sbf;
    //window.open(booking_url, '_blank');
});


  jq("[id$='txtOrigin']").on("keyup", function () {
    _ticketType = "bus";
    textFromBoxFetch();
});
jq("[id$='txtOrigin']").on("focus", function () {
    _ticketType = "bus";
    textFromBoxFetch();
});
jq("[id$='txtOrigin']").on("click", function () {
    _ticketType = "bus";
    jq(this).val("");
    textFromBoxFetch();
});
jq("[id$='txtDestination']").on("keyup", function () {
    _ticketType = "bus";
    textToboxFetch();
});
jq("[id$='txtDestination']").on("focus", function () {
    _ticketType = "bus";
    textToboxFetch();
});
jq("[id$='txtDestination']").on("click", function () {
    jq(this).val("");
    _ticketType = "bus";
    textToboxFetch();
});


jq("body").click(function (e) {
    var container = jq(".divSearchResultFrom");
    var containertext = jq(".txtFromLoc").parent("div").parent("div");
    if (!container.is(e.target) && container.has(e.target).length === 0 && !containertext.is(e.target) && containertext.has(e.target).length === 0) {
        container.hide();
    }
    container = jq(".divSearchResultTo");
    containertext = jq(".txtToLoc").parent("div").parent("div");
    if (!container.is(e.target) && container.has(e.target).length === 0 && !containertext.is(e.target) && containertext.has(e.target).length === 0) {
        container.hide();
    }
});


function textFromBoxFetch() {
    if (_ticketType == "bus")
        fetchFrom(jq.trim(jq("[id$='txtOrigin']").val()));
    jq(".txtToLoc").val("");
}
function textToboxFetch() {
    if (_ticketType == "bus")
        fetchTo(jq.trim(jq("[id$='txtOrigin']").val()), jq.trim(jq("[id$='txtOrigin']").attr("fromState")), jq.trim(jq("[id$='txtDestination']").val()));
}

const handleDeptChange = date => {
  setJournyStartDate(date);
  // Do whatever you want with the selected date here
  setJournyReturnDate(new Date(getDateTwoDaysLater(date)))
};

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {!isHome ? (
          // for home page
          <>
            {/* <ExchangePhoto
              headercolor={webSettingData?.buttoncolor}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ cursor: "pointer" }}
              onClick={handleSwap}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
              />
            </ExchangePhoto> */}
            <RadioInput>
                    <SinglePayment>
                      <PaymentInput
                        type="radio"
                        id="rb2"
                        name="way"
                        value="2"
                        checked={selectWay === "2"}
                        onChange={(e) => setSelectWay(e.target.value)}
                      />
                      <Label htmlFor="rb2">Return</Label>
                    </SinglePayment>
                    <SinglePayment>
                      <PaymentInput
                        type="radio"
                        id="rb1"
                        name="way"
                        value="1"
                        checked={selectWay === "1"}
                        onChange={(e) => setSelectWay(e.target.value)}
                      />
                      <Label htmlFor="rb1">One Way</Label>
                    </SinglePayment>
            </RadioInput>
            <InnerForm>

              <Location>
              <div style={{position:'relative'}}> 
                <SearchLocation
                type="text"
                id="txtOrigin"
                autoComplete="off"
                placeholder="Select a Departure Point"
                className="form-control txtFromLoc homeSearchText"
                />
                <LocationWrapper id="divSearchResultFrom"> 
                  <LocationUl className="select2-results">
                  </LocationUl>
                </LocationWrapper>
              </div>
              <div style={{position:'relative',flex:1}}>
                <SearchLocation
                 type="text"
                 id="txtDestination"
                 autoComplete="off"
                 placeholder="Select a Destination"
                 className="form-control txtToLoc homeSearchText"
                />
                <LocationWrapper id="divSearchResultTo"> 
                  <LocationUl className="select2-results">
                  </LocationUl>
                </LocationWrapper>
              </div>
              </Location>
            
              <DateInput>
                <JourneyDate>
                  <DateText>
                    {
                      languageData?.search_form_start_date[
                        webSettingData?.language
                      ]
                    }
                  </DateText>
                  <Calendar
                    id="txtDepartDate"
                    onChange={handleDeptChange}
                    value={journyStartDate}
                    clearIcon={null}
                    dateFormat="YYYY-MM-DD"
                    minDate={moment().toDate()}
                  />
                </JourneyDate>
                <ReturnDate>
                  {selectWay === '2' && <>
                  <DateText>
                    {
                      languageData?.search_form_retrurn_date[
                        webSettingData?.language
                      ]
                    }
                  </DateText>
                  <Calendar
                    id="txtReturnDate"
                    onChange={setJournyReturnDate}
                    valueDefault={null}
                    value={journyReturnDate}
                    clearIcon={null}
                    dateFormat="YYYY-MM-DD"
                    minDate={journyStartDate}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                  />
                  </>}
                </ReturnDate>
              </DateInput>
            </InnerForm>
            <SearchBtnArea>
              <SearchBtn
                searchbtnbgcolor={webSettingData?.buttoncolor}
                searchbtnbghvcolor={webSettingData?.buttoncolorhover}
                btntextcolor={webSettingData?.buttontextcolor}
                // type="submit"
                id="btnBusSearch"
              >
                {languageData?.search_form_button[webSettingData?.language]}
              </SearchBtn>
            </SearchBtnArea>
          </>
        ) : (
          <div>
            {/* for booking page */}

            <BookingPageExchangePhotoTwo>
              {/* <ExchangePhoto
                headercolor={webSettingData?.buttoncolor}
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                />
              </ExchangePhoto> */}
            </BookingPageExchangePhotoTwo>
            <BookingPageInnerForm>
              <BookingPageLocation>
                <SearchLocation
                type="text"
                id="txtOrigin"
                autocomplete="off"
                placeholder="Select a Departure Point"
                className="form-control txtFromLoc homeSearchText"
                 
                />
                {/* <LocationWrapper id="divSearchResultFrom"> 
                  <LocationUl className="select2-results">
                      <li>ssd</li>
                  </LocationUl>
                </LocationWrapper> */}
                <div
                  style={{
                    left: "0",
                    width: "95%",
                    top: "27px",
                    bottom: "auto",
                    position: "absolute",
                    backgroundColor: "#ffffff",
                    zIndex: "4",
                    border: "1px solid #cccccc",
                    borderTop: "none",
                    // display: "none",
                  }}
                />
                <SearchLocation
                  styles={cursorStyles}
                  placeholder={
                    languageData?.search_form_to_input[webSettingData?.language]
                  }
                  options={locations}
                  value={locations[getDropLocation(locations, values)]}
                  onChange={handleDroppingChange}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </BookingPageLocation>

              <BookingPageDate>
                <BookingPageJourneyDate>
                  <DateText>Depart</DateText>
                  <Calendar
                    onChange={setJournyStartDate}
                    value={journyStartDate}
                    clearIcon={null}
                    dateFormat="YYYY-MM-DD"
                    minDate={moment().toDate()}
                  />
                </BookingPageJourneyDate>
                <BookingPageReturnDate>
                  <DateText>Return (Optional)</DateText>
                  <Calendar
                    onChange={setJournyReturnDate}
                    value={journyReturnDate}
                    clearIcon={null}
                    dateFormat="YYYY-MM-DD"
                    minDate={moment().toDate()}
                    valueDefault={null}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                  />
                </BookingPageReturnDate>
              </BookingPageDate>
              <SearchBtnAreaFindTrip>
                <BookingPageSearchBtn
                  searchbtnbgcolor={webSettingData?.buttoncolor}
                  searchbtnbghvcolor={webSettingData?.buttoncolorhover}
                  btntextcolor={webSettingData?.buttontextcolor}
                >
                  {
                    languageData?.search_form_button_booking_page[
                      webSettingData?.language
                    ]
                  }
                </BookingPageSearchBtn>
              </SearchBtnAreaFindTrip>
            </BookingPageInnerForm>
          </div>
        )}
      </Form>
    </>
  );
};

export default SearchForm;
