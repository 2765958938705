export const setTourDetails = (payload) => {
  return {
    type: "SET_TOUR_DETAILS",
    payload,
  };
};

export const setTourPassengers = (payload) => {
  return {
    type: "SET_TOUR_PASSENGERS",
    payload,
  };
};

export const setTaxPercentage = (payload) => {
  return {
    type: "SET_TAX_PRECENTAGE",
    payload,
  };
};

export const setBaseFair = (payload) => {
  return {
    type: "SET_BASE_FAIR",
    payload,
  };
};

export const setHotelFair = (payload) => {
  return {
    type: "SET_HOTEL_FAIR",
    payload,
  };
};

export const setTourDiscount = (payload) => {
  return {
    type: "TOUR_DISCOUNT",
    payload,
  };
};

export const setTourBooking = (payload) => {
  return {
    type: "TOUR_BOOKING",
    payload,
  };
};

export const removeTourBooking = (payload) => {
  return {
    type: "REMOVE_TOUR_BOOKING",
    payload,
  };
};
