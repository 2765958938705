import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import Layout from "../../bootstrap/Layout";
import Spinner from "../../bootstrap/Spinner";
import TextField from "../../bootstrap/TextField/index.jsx";
import languageData from "../../lib/lang.config.json";
import {
  Container,
  FormHeader,
  FormWrapper,
  InnerFormArea,
  InnerFormPadding,
  InputField,
  SingupButton,
  SubHeader,
  SingupLink,
  PhoneWrapper,
} from "./ForgotPassword.styles.js";
import { LogoArea, FormLogo, Select, CountryCodeSelect, ModalHeader, ModalHeaderButton, ModalHeaderTitle, ScrollWrapper, SCCountryCodeList, SCCountryCodeItem } from "../Signup/Signup.styles";
import { SpinnerWrapper } from "../Checkout/Checkout.styles";
import { getFlagEmoji } from "../../helpers";
import IconArrowBack from "../../assets/images/icon-arrow-back.svg";
import Modal from "../../components/Ui/Modal";

export const ForgotPassword = () => {
  const { webSettingData } = useSelector((state) => state.busLists);
  const [values, setValues] = useState({
    mobile_country_code: "65",
    mobile_number: "",
  });
  const [userProfileInfo, setUserProfileInfo] = useState(null);
  const [countryName, setCountryName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const history = useHistory();const [filteredCountryList, setFilteredCountryList] = useState(countryName);
  const [country, setCountry] = useState({
    flag: "https://flagcdn.com/sg.svg",
    countryCode: "+65",
  });

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  useEffect(() => {
    setUserProfileInfo(JSON.parse(localStorage.getItem("userProfileInfo")));

    fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/countries`)
      .then((res) => res.json())
      .then((data) => {setCountryName(data.data);setFilteredCountryList(data.data);});    
  }, []);

  const openModelHandeler = () => {
    setOpenModel(true);
  }
  const onClose = () => {
    setOpenModel(false);
  setFilteredCountryList(countryName);

  };

  const handleCountrySelect = (country) => {
  setValues({ ...values, mobile_country_code: country?.phonecode });
  setCountry((prev) => ({
    ...prev,
    flag: `https://flagcdn.com/${(country?.iso).toLowerCase()}.svg`,
    // name: country?.name?.common,
    countryCode: country?.phonecode.startsWith("+")
    ? country?.phonecode
    : `+${country?.phonecode}`,
  }));
  onClose();
  };

  const filterCountryList = (event) => {
  const value = event.target.value;

  const filtered = countryName.filter((country) => {
    const countryCode = `${country?.phonecode}`;

    return (
      country?.name?.toLowerCase().includes(value.toLowerCase()) ||
      countryCode.includes(value)
    );
  });

  setFilteredCountryList(filtered);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const validatePhone = (phone) => {
    const phonePattern = /\b\d{6,14}\b/;
    const isValid = phonePattern.test(phone);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (values?.mobile_country_code === "") {
      toast.error("Country code is required");
      return;
    } else if (values?.mobile_number === "") {
      toast.error("Phone number is required");
      return;
    } else if (!validatePhone(values?.mobile_number)) {
      toast.error("Invalid phone number");
      return;
    }

    const formData = new FormData();
    formData.append("country_code", values?.mobile_country_code);
    formData.append("phone", values?.mobile_number);

    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_MODULE_DOMAIN}/website/phones/check/phone/pass`,
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      setIsLoading(false);

      if (result?.status === "success") {
        toast.success(result?.message);
        localStorage.setItem("OTP", JSON.stringify(result?.otp));
        localStorage.setItem("slug", JSON.stringify(result?.slug));
        history.push("/reset-password");
        setValues({
          mobile_country_code: "",
          mobile_number: "",
        });
      } else {
        toast.error(result?.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error");
    }
  };

  return (
    <Layout title="ForgotPassword" userProfileInfo={userProfileInfo}>
      <Container>
        {isLoading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <FormWrapper>
            <InnerFormArea>
              <InnerFormPadding>
                <LogoArea>
                  <FormLogo src={webSettingData?.headerlogo} alt="logo" />
                </LogoArea>
                <form onSubmit={handleSubmit}>
                  <FormHeader>
                    {languageData?.forgot_page_title[webSettingData?.language]}
                  </FormHeader>
                  <SubHeader>
                    {
                      languageData?.forgot_page_sub_title[
                        webSettingData?.language
                      ]
                    }
                  </SubHeader>
                  <InputField>
                    {/* <TextField
                  name="email"
                  img={emailPhoto}
                  alt="EmailIcon"
                  placeholder={
                    languageData?.forgot_page_email_input[
                    webSettingData?.language
                    ]
                  }
                  type="email"
                  onChange={handleChange}
                /> */}
                    <PhoneWrapper>
                      <CountryCodeSelect onClick={() => {openModelHandeler();}}>
                          <img src={country?.flag} alt={`${country?.name}`} />
                          <span>{`${country?.countryCode}`}</span>
                      </CountryCodeSelect>
                      <Modal
                        isOpen={openModel} onClose={onClose}>
                        <ModalHeader>
                            <ModalHeaderButton onClick={onClose}>
                              <img src={IconArrowBack} alt="" />
                            </ModalHeaderButton>
                            <ModalHeaderTitle>Select Country Code</ModalHeaderTitle>
                          </ModalHeader>
                          <TextField
                          name="contry_code"
                          type="text"
                          // value={values?.confirm_email}
                          placeholder="Search By Country or Country Code"
                          onChange={filterCountryList}
                          autoFocus={true}
                          autoComplete="off"
                        />
                        <ScrollWrapper> 
                          <SCCountryCodeList>
                            {filteredCountryList.map((country, index) => (
                              <SCCountryCodeItem
                                key={index}
                                onClick={() => handleCountrySelect(country)}
                              >
                                <img
                                  src={`https://flagcdn.com/${(country?.iso).toLowerCase()}.svg`}
                                  // alt={`${country?.name?.common} Flag`}
                                />
                                <span>
                                  {country?.name}{" "}
                                        {country?.phonecode.startsWith("+")
                                        ? `(${country?.phonecode})`
                                        : `(+${country?.phonecode})`}
                                </span>
                              </SCCountryCodeItem>
                            ))}
                          </SCCountryCodeList> 
                        </ScrollWrapper>
                      </Modal>
                      {/* <Select
                        name="mobile_country_code"
                        id="CountryCode"
                        onChange={handleChange}
                      >
                        <option value="" selected>
                          Country Code
                        </option>
                        {countryName?.map((item, index) => (
                          <option value={item.phonecode} key={index}>
                            {getFlagEmoji(item?.iso)}
                            {" "}
                            {item?.phonecode.startsWith("+")
                              ? item?.phonecode
                              : `+${item?.phonecode}`}
                          </option>
                        ))}
                      </Select> */}
                      <TextField
                        min="0"
                        name="mobile_number"
                        type="number"
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        placeholder={
                          languageData?.sign_up_page_input_phone[
                            webSettingData?.language
                          ]
                        }
                        alt="ContactIcon"
                        onChange={handleChange}
                      />
                    </PhoneWrapper>
                  </InputField>
                  <InputField>
                    <SingupButton
                      btnbgcolor={webSettingData?.buttoncolor}
                      btnbghvcolor={webSettingData?.buttoncolorhover}
                      btntextcolor={webSettingData?.buttontextcolor}
                    >
                      {
                        languageData?.forgot_page_submit_button[
                          webSettingData?.language
                        ]
                      }
                    </SingupButton>
                  </InputField>

                  <div>
                    <SingupLink
                      to="/login"
                      textcolor={webSettingData?.buttoncolor}
                    >
                      {
                        languageData?.forgot_password_page_qustion_text[
                          webSettingData?.language
                        ]
                      }
                    </SingupLink>
                  </div>
                </form>
              </InnerFormPadding>
              {/* end form area */}
            </InnerFormArea>
          </FormWrapper>
        )}
      </Container>
    </Layout>
  );
};
