import React from 'react';
import { ToursCardContainer, ToursCardImage, ToursCardBody, ToursCardTitle } from "./ToursCard.styles";

const ToursCard = ({ key, image, title }) => {
  return (
    <ToursCardContainer key={key} title={title?.toUpperCase()}>
      <ToursCardImage><img src={image} alt={title} /></ToursCardImage>
      <ToursCardBody>
        <ToursCardTitle>{title}</ToursCardTitle>
      </ToursCardBody>
    </ToursCardContainer>
  )
}

export default ToursCard;