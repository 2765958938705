import styled from "styled-components";

export const PayButton = styled.button`
  display: block;
  padding: 10px 20px;
  outline: none;
  // width:100%;
  // margin-top:4rem;
  color: #fff;
  font-size:14px;
  font-weight: 700;
  border-radius: 5px;
  border: 1px solid #2e2f7b;
  background: var(--primary-80, rgba(43, 48, 135, 0.8));
  margin-top:25px;
`;

export const PaymentFormWrapper = styled.div`
  width:30%;
  @media (max-width:768px)
  { width: 80%;}
`;

export const StripeWrapper = styled.div`
  display : flex;
  flex-direction : row;
  padding : 2rem 2.5rem;
  border: 1 solid gray;
  border-radius: .5rem;
  border: 1px solid gray;
`;

export const StripRightside = styled.div`
  padding-left: 2rem;
`;

export const PaymentInfo = styled.div`
  padding: 1rem;
  background: #EFF5F9;
  display:flex;
  flex-direction:column;
`;

export const TopInfo = styled.div`
  border-bottom: 1px dashed gray;
`;

export const BottomInfo = styled.div`
`;

