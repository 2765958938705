export const setAttractionGroupDetails = (payload) => {
  return {
    type: "SET_ATTRACTION_GROUP_DETAILS",
    payload,
  };
};
export const setAttractionPassengerInfo = (payload) => {
  return {
    type: "SET_ATTRACTION_PASSENGER_INFO",
    payload,
  };
};
