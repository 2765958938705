import React, { useState } from "react";
import ReactDOM from "react-dom";
import { StyledModalOverlay, StyledModalContainer } from "./Modal.styles";

const Modal = ({ children, isOpen, onClose, width, padding }) => {
  return (
    <>
      {isOpen && <StyledModalOverlay onClick={() => onClose()} />}
      {isOpen && (
        <StyledModalContainer width={width} padding={padding}>
          {children}
        </StyledModalContainer>
      )}
    </>
  );
};

ReactDOM.createPortal(Modal, document.getElementById("modal-root"));

export default Modal;