export const setUserSignUpDetails = (payload) => {
  return {
    type: "SET_USER_SIGN_UP_DETAILS",
    payload,
  };
};

export const setUserNewSignUpDetails = (payload) => {
  return {
    type: "SET_USER_NEW_SIGN_UP_DETAILS",
    payload,
  };
};