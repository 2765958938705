const initialState = {
  DepSeats: [],
  RetSeats: [],
  DepBusId: "",
  RetBusId: "",
  DeptFrom: "",
  DeptTo: "",
  RetFrom: "",
  RetTo: "",
  DepSeatNumbers: "",
  RetSeatNumbers: "",
};

const twoWayBookingDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TWO_WAY_BOOKING_DETAILS_DepSeats": {
      let updatedDepSeats;
      const {index,trip}=action.payload;
      if (state?.DepSeats?.includes(index)) {
        updatedDepSeats = state.DepSeats.filter(
          (seat) => seat !== index
        );
      } else {
        updatedDepSeats = [...state.DepSeats, index];
      }

      return {
        ...state,
        DepSeats: updatedDepSeats,
        DepBusId: trip?.Index,
        DeptFrom: trip?.From_Str,
        DeptTo: trip?.To_Str,
      };
    }
    case "REMOVE_TWO_WAY_BOOKING_DETAILS_DepSeats": {
      const newState = {
        ...state,
        DepSeats: [],
        DepBusId: "",
        DeptFrom: "",
        DeptTo: "",
      };
      return newState;
    }

    case "SET_TWO_WAY_BOOKING_DETAILS_RetSeats": {
      let updatedRetSeats;
      const {index,trip}=action.payload;
      if (state?.RetSeats?.includes(index)) {
        updatedRetSeats = state.RetSeats.filter(
          (seat) => seat !== index
        );
      } else {
        updatedRetSeats = [...state.RetSeats, index];
      }

      return {
        ...state,
        RetSeats: updatedRetSeats,
        RetBusId: trip?.Index,
        RetFrom: trip?.From_Str,
        RetTo: trip?.To_Str,
      };
    }
    case "REMOVE_TWO_WAY_BOOKING_DETAILS_RetSeats": {
      const newState = {
        ...state,
        RetSeats: [],
        RetBusId: "",
        RetFrom: "",
        RetTo: "",
      };
      return newState;
    }
    case "SET_TWO_WAY_BOOKING_DETAILS_DepSeatNumbers": {
      const newState = {
        ...state,
        DepSeatNumbers: action.payload,
      };
      return newState;
    }
    case "REMOVE_TWO_WAY_BOOKING_DETAILS_DepSeatNumbers": {
      const newState = {
        ...state,
        DepSeatNumbers: "",
      };
      return newState;
    }
    case "SET_TWO_WAY_BOOKING_DETAILS_RetSeatNumbers": {
      const newState = {
        ...state,
        RetSeatNumbers: action.payload,
      };
      return newState;
    }
    case "REMOVE_TWO_WAY_BOOKING_DETAILS_RetSeatNumbers": {
      const newState = {
        ...state,
        RetSeatNumbers: "",
      };
      return newState;
    }
    default: {
      return state;
    }
  }
};

export default twoWayBookingDetailsReducer;
