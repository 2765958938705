const initialState = {
  attractionGroupDetails: null,
};

const attractionGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ATTRACTION_GROUP_DETAILS": {
      return {
        ...state,
        attractionGroupDetails: action.payload,
      };
    }
    case "SET_ATTRACTION_PASSENGER_INFO": {
      return {
        ...state,
        attractionPassengerInfo: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default attractionGroupReducer;
