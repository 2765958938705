import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router";
import {
  clearSavedAttractionBookIds,
  clearSavedBookIds,
  openModel,
  regularBookingInformation,
  ticketTracking,
} from "../../redux/action/busAction";
import { toast } from "react-toastify";
import { PayButton,PaymentFormWrapper } from "./PaymentForm.styles";
import { removeTourBooking } from "../../redux/action/tourBookingAction";
import TextField from "../../bootstrap/TextField";
import QrCode from "../../assets/images/qrcode.png"
import CryptoJS from "crypto-js";

const PaymentForm = ({ type,payment }) => {
  const [message, setMessage] = useState();
  const [isProcessing, setIsProcessing] = useState();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const dispatch = useDispatch();
  const { bookingIds, bookingAttractionIds } = useSelector(
    (state) => state.busLists
  );
  const { tourBooking } = useSelector((state) => state?.tourBooking);

  const token = localStorage.getItem("token");
  const discount = JSON.parse(localStorage.getItem("discount"));
  const userId = JSON.parse(localStorage.getItem('userProfileInfo'));

  const updateTransaction = async (item, resp) => {
    try {
      const data = !discount ? {
        booking_id: item.booking_id,
        payment_response: JSON.stringify(resp),
        pay_method: 2,
        paydetail: "stripe payment",
        payment_id: resp.id,
        pay_type_id: 3,
        coupon:0,
        userId:userId?.user_id
      }:{
        booking_id: item.booking_id,
        payment_response: JSON.stringify(resp),
        pay_method: 2,
        paydetail: "stripe payment",
        payment_id: resp.id,
        pay_type_id: 3,
        coupon:1,
        amount:discount?.discount,
        couponCode:discount?.couponCode,
        couponId: parseInt(discount?.couponId),
        type: "trip",
        userId:userId?.user_id,
        relativeId : parseInt(discount?.relativeId),
      };
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const encryData = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        process.env.REACT_APP_SECRET_PASS
      ).toString();
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_MODULE_DOMAIN}/tickets/updateTransaction`,
        {sData:encryData},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.data.response == "200") {
        dispatch(clearSavedBookIds());
        window.isPaymentPending= false
        dispatch(ticketTracking(response?.data?.data));
        history.replace("/ticket-traking");
        dispatch(openModel(true))
        // toast.success("Payment successful! Your tickets have been booked.");
      }
    } catch (error) {
      setIsProcessing(false)
    }
    localStorage.removeItem("subtripId");
    localStorage.removeItem("discount");
  };
  const updateTourTransaction = async (item,payment,resp) => {
    try {
      const data = !discount ? {
        ticketId: item.ticketId,
        bookingId: item.bookingId,
        paymentType: "stripe",
        paymentResponse:  { paymentIntent: resp },
        coupon:0,
        userId:userId?.user_id
      }:
      {
        ticketId: item.ticketId,
        bookingId: item.bookingId,
        paymentType: "stripe",
        paymentResponse:  { paymentIntent: resp },
        coupon:1,
        amount:discount?.discount,
        couponCode:discount?.couponCode,
        couponId: parseInt(discount?.couponId),
        type: "tour",
        userId:userId?.user_id,
        relativeId : parseInt(discount?.relativeId),

      };

      const encryTourData = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        process.env.REACT_APP_TOUR_SECRET_PASS
      ).toString();

      const response = await axios.post(
        `${process.env.REACT_APP_API_MODULE_DOMAIN}/tours/payment`,
        {sData:encryTourData},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response?.data?.response == "200") {
        dispatch(ticketTracking(response?.data?.data));
        dispatch(regularBookingInformation(" "));
        window.isPaymentPending= false
        history.replace("/ticket-traking");
        dispatch(openModel(true))
        // toast.success("Payment successful! Your tickets have been booked.");
      }
    } catch (error) {
      setIsProcessing(false);
      toast.error("Payment failed!!");
    }
    localStorage.removeItem("subtripId");
    localStorage.removeItem("discount");
  };
  const updateAttractionTransaction = async (item, resp) => {
    try {
      const data = !discount ? {
        ticketId: item.id,
        bookingId: item.booking_id,
        paymentType: "stripe",
        paymentResponse: { paymentIntent: resp },
        coupon:0,
        userId:userId?.user_id
      } : 
      {
        ticketId: item.id,
        bookingId: item.booking_id,
        paymentType: "stripe",
        paymentResponse: { paymentIntent: resp },
        coupon:1,
        amount:discount?.discount,
        couponCode:discount?.couponCode,
        couponId: parseInt(discount?.couponId),
        type: "attraction",
        userId:userId?.user_id,
        relativeId : parseInt(discount?.relativeId),
      };

      const encryAttData = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        process.env.REACT_APP_ATT_SECRET_PASS
      ).toString();

      const response = await axios.post(
        `${process.env.REACT_APP_API_MODULE_DOMAIN}/attractions/payment`,
        {sData:encryAttData},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.data.response == "200") {
        dispatch(ticketTracking(response?.data?.data));
        window.isPaymentPending= false;
        dispatch(regularBookingInformation(" "));
        history.replace("/ticket-traking");
        dispatch(openModel(true))
        // toast.success("Payment successful! Your tickets have been booked.");
      }
    } catch (error) {
      setIsProcessing(false);
      toast.error("Payment failed!!");
    }
    localStorage.removeItem("subtripId");
    localStorage.removeItem("discount");

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/`,
      },
      redirect: "if_required",
    });
    if (error) {
      setMessage(error.message);
      setIsProcessing(false);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      if (type === "trip") {
        if (bookingIds.length) {
          await Promise.all(
            bookingIds.map((item) => updateTransaction(item, paymentIntent))
          );
        }
      } else if (type === "attraction") {
        if (bookingAttractionIds.length) {
          await Promise.all(
            bookingAttractionIds.map((item) =>
              updateAttractionTransaction(item, paymentIntent)
            )
          );
          dispatch(clearSavedAttractionBookIds());
        }
      } else if (type === "tour") {
        if (tourBooking) {
          await updateTourTransaction(tourBooking,payment,paymentIntent);
          dispatch(removeTourBooking());
        }
      }
    }
    setIsProcessing(false);
  };
  const handlePageLeave = (event) => {
    // Remove your local storage items or perform other actions here
    localStorage.removeItem("searchInfo");
    localStorage.removeItem("bookingInfo");
    localStorage.removeItem("tourBookingDetails");
    localStorage.removeItem("subtripId");
    localStorage.removeItem("discount");
    console.log("User is navigating away from the page");
  };

  useEffect(() => {
    // Listen for the beforeunload event when the component mounts
    window.addEventListener("beforeunload", handlePageLeave);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handlePageLeave);
    };
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (window.isPaymentPending) {
        if (window.confirm("Are you sure you want to leave?")) {
          sessionStorage.setItem("remainingSeconds", "0");
        } else {
          // Prevent the navigation by returning a message
          event.preventDefault();
          event.returnValue = "Are you sure you want to leave? handleBeforeUnload";
        }
      }
    };
    const handleUnload = () => {
      sessionStorage.setItem("remainingSeconds", "0");
    };

    const unblock = history.block((location, action) => {
      if (window.isPaymentPending) {
        if (window.confirm(`Are you sure you want to leave?`)) {
          sessionStorage.setItem("remainingSeconds", "0");
        } else {
          return false;
        }
      } else {
        unblock()
      }
    });
    window.isPaymentPending= true;
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
      window.isPaymentPending= true;
      unblock();
    };
  }, []);
  return (
    <div>
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <PaymentFormWrapper
          style={{
            display: "inherit",
            flexDirection: "inherit",
            gap: "15px",
          }}
        >
          <PaymentElement /> 
            
          <PayButton disabled={isProcessing} id="submit">
            <span id="button-text">
              {isProcessing ? "Loading ..." : "Pay Now"}
            </span>
          </PayButton>
        </PaymentFormWrapper>
      </form>
    </div>
  );
};

export default React.memo(PaymentForm);