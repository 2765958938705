import { toast } from "react-toastify";
import {
  addBusName,
  addError,
  regularBookingInformation,
  saveBookidIds,
  updateFareSummery,
} from "../../../redux/action/busAction";
import moment from "moment";

export function groupObjects(inputObject) {
  const outputObjects = [];

  for (const key in inputObject) {
    if (key.match(/^(.*?)(\d+)$/)) {
      const [, fieldName, groupNumber] = key.match(/^(.*?)(\d+)$/);
      if (!outputObjects[groupNumber]) {
        outputObjects[groupNumber] = {};
      }
      outputObjects[groupNumber][fieldName] = inputObject[key];
    }
  }

  // Filter out any undefined group objects (in case some groups were skipped)
  const formattedOutput = outputObjects
    .filter((group) => group !== undefined)
    .map((group) => ({
      first_name: group.name,
      last_name: group.surName,
      dob: group.dob,
      id_number: group.passPort,
      id_type: group.id_type || "NRIC",
      gender: group.gender,
      country: group.country || "",
      title: group.title,
      passport_expiry: group.passport_expiry || null
    }));

  return formattedOutput;
}

export function checkForNullValues(data, id) {
  const uniqueIdNumbers = [];
  uniqueIdNumbers.push(id);
  for (const obj of data) {
    if (uniqueIdNumbers.includes(obj?.id_number)) {
      return "Same NRIC found for passengers check again.";
    }
    uniqueIdNumbers.push(obj?.id_number);
    if (!obj.first_name) {
      return "First name is required.";
    }
    if (!obj.last_name) {
      return "Last name is required.";
    }
    if (!obj.dob) {
      return "Date of birth is required.";
    }
    const currentDate = new Date(); // Get the current date
    const dobDate = new Date(obj.dob); // Convert the DOB string to a Date object
    const dobFormatted = moment(dobDate).format("YYYY-MM-DD");
    if (dobDate > currentDate) {
      return "Date of birth cannot be a future date.";
    }
    if (dobFormatted < moment().subtract(100, "years").format("YYYY-MM-DD")) {
      return "Invalid Date Of Birth";
    }
    if (!obj.id_number) {
      return "Document number is required.";
    }
    if (!obj.id_type) {
      return "ID type is required.";
    }
    if (!obj.gender) {
      return "Gender is required.";
    }
    if (!obj.title) {
      return "Title is required.";
    }
    // if (!obj.country) {
    //   return "Country is required";
    // }
  }

  return null;
}

export const payLatter = async (
  bookingData,
  allBookingInformation,
  dispatch
) => {
  const {
    totalprice,
    passengerInformation,
    values,
    setValues,
    paymentStutas,
    paymentGateway,
    fareSummry,
    searchInfo,
    bookingInfo,
    journeyInfo,
    returnSearchInfo,
    returnFirstJourneyInfo,
    returnAmmount,
    regularAmmount,
    discountValue,
    subTripid,
    journeyInfoTax,
    bookingInfoTax,
  } = allBookingInformation;

  const otherPassengerInfo = groupObjects(values) || [];
  bookingData.otherPassengerInfo = otherPassengerInfo;
  bookingData.subPassenger_count = otherPassengerInfo.length || 0;

  if (journeyInfo?.isRoundTrip) {
    bookingData.pick_location_id = returnFirstJourneyInfo.pickLocation;
    bookingData.drop_location_id = returnFirstJourneyInfo.dropLocation;

    bookingData.pickstand = journeyInfo.pickstand;
    bookingData.dropstand = journeyInfo.dropstand;
    bookingData.journeydate = journeyInfo.journeydate;
    bookingData.returndate = journeyInfo.returnDate;
    bookingData.totalprice = journeyInfo.totalprice;
    // test start
    bookingData.payment_status = "unpaid";

    bookingData.seatnumbers = journeyInfo?.seatnumbers;
    bookingData.grandtotal = regularAmmount;
    if (subTripid === journeyInfo?.subtripId) {
      bookingData.discount = discountValue;
    } else {
      bookingData.discount = 0;
    }
    bookingData.tax = journeyInfoTax;
    //extra passenger

    bookingData.trip_id = journeyInfo.trip_id;
    bookingData.subtripId = journeyInfo.subtripId;
   
    const response = await fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/tickets/unpaid/booking`,
      {
        method: "POST",
        body: JSON.stringify(bookingData),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    // If success then submit return ticket information
    if (result.status === "success") {
      dispatch(saveBookidIds(result.data));
      if (bookingInfo?.seatnumbers) {
        dispatch(regularBookingInformation(result?.data?.booking_id));

        bookingData.pick_location_id = searchInfo.pickLocation;
        bookingData.drop_location_id = searchInfo.dropLocation;

        bookingData.pickstand = bookingInfo.pickstand;
        bookingData.dropstand = bookingInfo.dropstand;
        bookingData.totalprice = bookingInfo.totalprice;

        bookingData.journeydate = searchInfo.journeydate;
        bookingData.returndate = "";
        // test start
        bookingData.payment_status = "unpaid";

        bookingData.seatnumbers = bookingInfo?.seatnumbers;
        bookingData.grandtotal = returnAmmount;
        if (subTripid === bookingInfo?.subtripId) {
          bookingData.discount = discountValue;
        } else {
          bookingData.discount = 0;
        }
        bookingData.tax = bookingInfoTax;

        // test end

        bookingData.trip_id = bookingInfo.trip_id;
        bookingData.subtripId = bookingInfo.subtripId;

        const response2 = await fetch(
          `${process.env.REACT_APP_API_MODULE_DOMAIN}/tickets/unpaid/booking`,
          {
            method: "POST",
            body: JSON.stringify(bookingData),
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const result2 = await response2.json();
        if (result2.status === "success") {
          dispatch(saveBookidIds(result2.data));
          dispatch(updateFareSummery(null));
          // dispatch(addBusName([]))
          localStorage.removeItem("journeyInfo");
          // localStorage.removeItem("searchInfo");
          localStorage.removeItem("returnFirstJourneyInfo");
          // localStorage.removeItem("bookingInfo");

          toast.success(
            "Your booking has been done successfully, now let's make payment"
          );
          return Promise.resolve(result2);
        }
        return Promise.reject(result2);
      } else {
        dispatch(addError(""));
        dispatch(updateFareSummery(null));
        // dispatch(addBusName([]))
        localStorage.removeItem("journeyInfo");
        // localStorage.removeItem("searchInfo");
        localStorage.removeItem("returnFirstJourneyInfo");
        // localStorage.removeItem("bookingInfo");
        return Promise.resolve(result);
      }
    }
    return Promise.reject(result);
  } else if (!bookingInfo?.isRoundTrip) {
    bookingData.payment_status = "unpaid";

    bookingData.grandtotal = returnAmmount;
    bookingData.discount = discountValue ? discountValue : 0;
    bookingData.tax = bookingInfoTax;
    
    const response = await fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/tickets/unpaid/booking`,
      {
        method: "POST",
        body: JSON.stringify(bookingData),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    if (result.status === "success") {
      dispatch(saveBookidIds(result.data));
      // dispatch(addBusName([]))
      // localStorage.removeItem("searchInfo");
      // localStorage.removeItem("bookingInfo");
      setValues({
        login_email: "",
        login_mobile: "",
        first_name: "",
        last_name: "",
        id_type: "",
        country_id: "",
        id_number: "",
        address: "",
        city: "",
        zip_code: "",
        title: "",
        passport_expiry: ""
      });

      toast.success(
        "Your booking has been done successfully, now let's make payment"
      );
      return Promise.resolve(result);
    }
    return Promise.reject(result);
  }
};
