import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import Container from '../../../bootstrap/Container';
import { LoaderContainer, StyledAttractions, StyledSubHeading , StyledHeading} from "./Attractions.styles";
import { LoadMoreBtn } from '../Tours/Tours.styles';
import { ErrorMsg } from "../../../pages/Booking/Booking.styles";
import ToursCard from '../ToursCard';
import { setAttractionGroupDetails } from "../../../redux/action/attractionGroupsAction";
import { Loader } from '../../../bootstrap';

const Attraction = ({ heading,subHeading, showLoadMoreButton }) => {
  const attractionLimit = 8;
  const dispatch = useDispatch();
  const { webSettingData } = useSelector((state) => state.busLists);
  const [attractions, setAttractions] = useState([]);
  const [totalAttractions, setTotalAttractions] = useState(null);
  const [page, setPage] = useState(1);
  const [loding, setLoading] = useState(true);

  const getAttractions = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/attractions/package?page=${page}&pageSize=${attractionLimit}`);
      const result = await response.json();
      if (result.totalCount > 0) {
        const newAttractions = attractions.concat(result.data);
        setAttractions(newAttractions);
        setLoading(false)
      } else {
        setAttractions([]);
        setLoading(false)
      }
      setTotalAttractions(result.totalCount);
    } catch (error) {
      setLoading(false)
      console.error("Attractions error", error);
    }
  }

  useEffect(() => {
    getAttractions();
  }, [page])

  const viewMoreAttractions = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleAttractionGroups = (attraction) => {
    dispatch(setAttractionGroupDetails(attraction));
  };

  return (
    <Container>
      {heading && <StyledHeading>{heading}</StyledHeading>}

      {subHeading && <StyledSubHeading>{subHeading}</StyledSubHeading>}

      <StyledAttractions>
        {attractions?.length === 0 ?

          !loding ? <ErrorMsg>
            Sorry no attractions found.
          </ErrorMsg> : <LoaderContainer ><Loader /></LoaderContainer>
          :
          attractions.map(attraction => {
            return (
              <NavLink
                key={attraction?.attraction_id}
                onClick={() => handleAttractionGroups(attraction)}
                to={{
                  pathname: `/attractions/${attraction?.country_id}`,
                  state: {
                    thumbnail: attraction?.thumbnail,
                    attraction_name: attraction?.attraction_name,
                  }
                }}>
                <ToursCard
                  key={attraction?.attraction_id}
                  image={attraction?.thumbnail}
                  title={`${attraction?.country_name} packages`}
                />
              </NavLink>
            )
          })
        }
      </StyledAttractions>

      {showLoadMoreButton
        && attractions?.length > 0
        && attractions?.length !== totalAttractions
        && <LoadMoreBtn LoadMoreBtn
          onClick={() => viewMoreAttractions()}
          btnbgcolor={webSettingData?.buttoncolor}
          btnbghvcolor={webSettingData?.buttoncolorhover}
          btntextcolor={webSettingData?.buttontextcolor}
        >view more</LoadMoreBtn>
      }
    </Container >
  );
}

export default Attraction;