import React from "react";
import TextField from "../../../bootstrap/TextField";
import { Select } from "./singleTravellerInformation.styles";
import {
  FlexCol,
  FlexRow,
  Grid,
} from "../TravellerInformation/TravellerInformation.styles";
import languageData from "../../../lib/lang.config.json";
import moment from "moment";
import { useSelector } from "react-redux";

const SingleTravellerInformation = ({
  values,
  passengerIndex,
  handleChange,
  countries,
}) => {
  const { webSettingData } = useSelector((state) => state.busLists);
  return (
    <>
      <Grid>
        {/* Title */}
        <FlexCol>
        <label htmlFor={`title${passengerIndex}`}>Title*</label>
          <Select
            name={`title${passengerIndex}`}
            id={`title${passengerIndex}`}
            onChange={handleChange}
            required
          >
            <option value="">Select Title</option>            
            <option value="Mr.">Mr.</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Mdm">Mdm</option>
            <option value="Ms">Ms</option>
            <option value="Mstr">Mstr</option>
            <option value="Dr">Dr</option>
            <option value="Prof">Prof</option>
            <option value="Others">Others</option>
          </Select>
        </FlexCol>
        {/* first name */}
        <FlexCol>
          <label htmlFor="name">First Name*</label>
          <TextField
            id={`name${passengerIndex}`}
            name={`name${passengerIndex}`}
            type="text"
            placeholder="First Name"
            value={values[`name${passengerIndex}`]}
            onChange={handleChange}
            autoComplete="nope"
          />
        </FlexCol>
        {/* last name */}
        <FlexCol>
          <label htmlFor="name">Last Name*</label>
          <TextField
            type="text"
            placeholder="Last Name"
            name={`surName${passengerIndex}`}
            value={values[`surName${passengerIndex}`]}
            onChange={handleChange}
            autoComplete="nope"
          />
        </FlexCol>
        {/* dob */}
        <FlexCol>
          <label htmlFor="dob">Date of Birth*</label>
          <TextField
            id={`dob${passengerIndex}`}
            name={`dob${passengerIndex}`}
            type="date"
            value={values[`dob${passengerIndex}`]}
            onChange={handleChange}
            min={moment().subtract(100, "years").format("YYYY-MM-DD")}
            max={new Date().toISOString().split('T')[0]}
            required
          />
        </FlexCol>
        {/* gender */}

        <FlexCol>
          <label htmlFor={`gender${passengerIndex}`}>Gender*</label>
          <Select
            name={`gender${passengerIndex}`}
            id={`gender${passengerIndex}`}
            onChange={handleChange}
          >
            <option value="">Select Gender</option>            
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Select>
        </FlexCol>
        
        {/* document number */}
        <FlexCol>
          <label htmlFor={`NRIC${passengerIndex || ""}`}>
            {languageData?.checkout_page_nid_title[webSettingData?.language]}*
          </label>
          <FlexRow>
            <Select
              name={`id_type${passengerIndex}`}
              id={`id_type${passengerIndex}`}
              onChange={handleChange}
            >
              <option value="NRIC">NRIC</option>
              <option value="Passport">PP</option>
            </Select>
            <TextField
              id={`NRIC${passengerIndex ? passengerIndex : ""}`}
              name={`passPort${passengerIndex}`}
              type="text"
              placeholder="Document No"
              value={values[`passPort${passengerIndex}`]}
              onChange={handleChange}
            />
          </FlexRow>
        </FlexCol>
        {/* passport expiry */}
        <FlexCol>
          <label htmlFor="passport_expiry">Passport Expiry Date</label>
          <TextField
            id={`passport_expiry${passengerIndex}`}
            name={`passport_expiry${passengerIndex}`}
            type="date"
            value={values[`passport_expiry${passengerIndex}`]}
            onChange={handleChange}
            // min={moment().subtract(100, "years").format("YYYY-MM-DD")}
            // max={new Date().toISOString().split('T')[0]}
            // required
          />
        </FlexCol>
        {/* country */}
        <FlexCol>
          <label htmlFor={`country${passengerIndex || ""}`}>Nationality</label>
          <Select
            id={`country${passengerIndex || ""}`}
            name={`country${passengerIndex}`}
            onChange={handleChange}
            style={{width:'100%'}}
          >
            <option value="">Select Country</option>
            {countries.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.label}
                </option>
              );
            })}
          </Select>
        </FlexCol>
      </Grid>
    </>
  );
};

export default SingleTravellerInformation;
