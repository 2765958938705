import styled from "styled-components";

export const ToursCardContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
  @media (max-width: 768px) {
    border-radius:5px;
  }
`;

export const ToursCardImage = styled.div`
  aspect-ratio: 16/ 9;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    overflow: contain;
    transition: all 1 ease-in-out;
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
  }
`;

export const ToursCardBody = styled.div`
  background: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
`;

export const ToursCardTitle = styled.p`
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
