import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
`;
export const HeroArea = styled.div`
  position: relative;
`;
export const SearchFromArea = styled.div`
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 5px 8px 0px;
  background: white;
  max-width: 900px;
  width: 100%;
  left: 50%;
  position: absolute;
  bottom: -37%;
  transform: translate(-50%, -50%);
  @media (max-width: 1024px) {
    width: 90%;
    bottom: -16%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 425px) {
    bottom: -44%;
  }
`;
export const CrossImgWrapper = styled.div`
  position: relative;
`;
export const TourWrapper = styled.div`
  margin-top:25px;
`;
export const CrossBoarderImg = styled(Link)`
width: 100%;
height: auto;
overflow: hidden;

  img {
    height: auto;
    max-width: 100%;
    object-fit: cover;
    margin: 100px 0 20px 0;
  }
@media (max-width: 768px) {
  img{
    margin: 0 0 20px 0;
  }
}
`;

export const CrossImage = styled.div`
margin:0 auto;
margin-bottom:30px;
@media (min-width: 320px) {
  max-width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}
@media (min-width: 420px) {
  max-width: 100%;
}
@media (min-width: 576px) {
  max-width: 540px;
}
@media (min-width: 768px) {
  max-width: 720px;
}
@media (min-width: 992px) {
  max-width: 960px;
}
@media (min-width: 1200px) {
  max-width: 1140px;
}
@media (min-width: 1400px) { 
    max-width: 1320px;
}
`