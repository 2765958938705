const initialState = {
  tourDetails: null,
  tourPassengerInfo: null,
  taxPercentage: 0,
  baseFair: 0,
  hotelFair: 0,
  tourDiscount: 0,
  tourBooking: null,
};

const tourBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOUR_DETAILS": {
      return {
        ...state,
        tourDetails: action.payload,
      };
    }
    case "SET_TOUR_PASSENGERS": {
      return {
        ...state,
        tourPassengerInfo: action.payload,
      };
    }
    case "SET_TAX_PRECENTAGE": {
      return {
        ...state,
        taxPercentage: action.payload,
      };
    }
    case "SET_BASE_FAIR": {
      return {
        ...state,
        baseFair: action.payload,
      };
    }
    case "SET_HOTEL_FAIR": {
      return {
        ...state,
        hotelFair: action.payload,
      };
    }
    case "TOUR_DISCOUNT": {
      return {
        ...state,
        tourDiscount: action.payload,
      };
    }
    case "TOUR_BOOKING": {
      return {
        ...state,
        tourBooking: action.payload,
      };
    }
    case "REMOVE_TOUR_BOOKING": {
      return {
        ...state,
        tourBooking: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default tourBookingReducer;
