import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import TextField from "../../../bootstrap/TextField";
import languageData from "../../../lib/lang.config.json";
import { CountrySelect } from "../../../pages/Signup/Signup.styles";
import { ticketTracking } from "../../../redux/action/busAction";
import Payment from "../Payment/index.jsx";
import { PaymentHelper } from "../PaymentGateway/PaymentHelper";
import SingleTravellerInformation from "../SingleTravellerInformation";
import { Select } from "../SingleTravellerInformation/singleTravellerInformation.styles";
import {
  Grid,
  FlexCol,
  InformationHeader,
  TravelarInformation,
  FlexRow,
  PaymentWrapper,
  PassengerAccordionWrapper,
  PassengerHeader,
  AccordionContent,
  Calendars,
  } from "./TravellerInformation.styles.js";
import moment from "moment";
import { Calendar } from "../SearchFrom/SearchFrom.styles";
import { StyledFontAwesomeIcon } from "../TourTravellerInformation/TourTravellerInformation.styles";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { dateForm } from "../../../helpers.js";
const TravellerInformation = ({
  passengerInformation,
  setPassengerInformation,
  values,
  setValues,
  handleChange,
  isRegesteredUser,
  discountValue,
  setDiscountValue,
  returnAmmount,
  regularAmmount,
  subTripid,
  setLoading,
  token,
  paymentStutas,
  setPaymentStutas,
  paymentGateway,
  setpaymentGateway,
}) => {
  const { webSettingData } = useSelector((state) => state.busLists);
  const fareSummery = useSelector((state) => state.fareSummery);
  const [countryName, setCountryName] = useState([]);
  const [searchInfo, setSearchInfo] = useState(null);
  const [bookingInfo, setBookingInfo] = useState(null);
  // const [paymentGateway, setpaymentGateway] = useState("");
  const [extraPassengers, setExtraPassengers] = useState({});
  const [roundTripPassengers, setRoundTripPassengers] = useState({});
  const [journeyInfo, setJourneyInfo] = useState({});
  const [returnSearchInfo, setReturnSearchInfo] = useState({});
  const [returnFirstJourneyInfo, setReturnFirstJourneyInfo] = useState({});
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [journeyInfoTax, setJourneyInfoTax] = useState(null);
  const [bookingInfoTax, setBookingInfoTax] = useState(null);
  const [singlePaymentGateway, setSinglePaymentGateway] = useState(null);
  const [paymentGatewayNumber, setPaymentGatewayNumber] = useState(null);
  const [userProfileInfo, setUserProfileInfo] = useState({});
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setUserProfileInfo(JSON.parse(localStorage.getItem("userProfileInfo")));
  }, [passengerInformation, token]);

  const dispatch = useDispatch();

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  // useEffect(() => {
  //   countryName?.find((item, index) => {
  //     if (item?.id == passengerInformation?.country_id) {
  //       setPassengerInformation({
  //         ...passengerInformation,
  //         country_id: item?.id,
  //       });
  //     }
  //   });
  // }, [countryName]);

  const handleCountryChange = (e) => {
    const { name, value } = e.target;
    setPassengerInformation({
      ...passengerInformation,
      [name]: value,
    });
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/countries`)
      .then((res) => res.json())
      .then((data) => setCountryName(data.data));
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/paymethods`)
      .then((res) => res.json())
      .then((data) => {
        setSinglePaymentGateway(data?.data);
        if (singlePaymentGateway) {
          const result = singlePaymentGateway?.find(
            (item) => item?.name === paymentGateway
          );
          setPaymentGatewayNumber(result?.id);
        }
      });
  }, [paymentGateway]);

  const getCountry = () =>
    countryName.findIndex(
      (country) =>
        String(country.id) === String(passengerInformation?.country_id)
    );
  useEffect(() => {
    const bookingInfo = localStorage.getItem("bookingInfo");
    if (bookingInfo === undefined || bookingInfo === null || !bookingInfo) {
      toast.error("Please select the trip")
      history.goBack()
    }
  }, [])
  useEffect(() => {
    setSearchInfo(JSON.parse(localStorage.getItem("searchInfo")));
    setBookingInfo(JSON.parse(localStorage.getItem("bookingInfo")));
    setJourneyInfo(JSON.parse(localStorage.getItem("journeyInfo")));
    setReturnSearchInfo(JSON.parse(localStorage.getItem("returnSearchInfo")));
    setReturnFirstJourneyInfo(
      JSON.parse(localStorage.getItem("returnFirstJourneyInfo"))
    );

    setJourneyInfoTax(JSON.parse(localStorage.getItem("journeyInfoTax")));
    setBookingInfoTax(JSON.parse(localStorage.getItem("bookingInfoTax")));
    setpaymentGateway('')
  }, [passengerInformation]);

  useEffect(() => {
    let extraPassengers = [],
      roundTripPassengers = [];

    if (journeyInfo?.isRoundTrip && bookingInfo?.totalseat > 1) {
      for (let i = 0; i < bookingInfo?.totalseat; i++) {
        roundTripPassengers.push({});
      }

      for (let i = 0; i < journeyInfo?.totalseat; i++) {
        extraPassengers.push({});
      }

      setExtraPassengers(extraPassengers);
      setRoundTripPassengers(roundTripPassengers);
    } else {
      for (let i = 0; i < bookingInfo?.totalseat; i++) {
        extraPassengers.push({});
      }
      setExtraPassengers(extraPassengers);
    }
  }, [bookingInfo]);

  let countries = [];
  countryName.map((item) =>
    countries.push({
      value: item.name,
      label: item.name,
      id: item.id,
    })
  );

  const handleCountry = (selectOption) => {
    setPassengerInformation({
      ...passengerInformation,
      country_id: selectOption.id,
    });
  };
  const [activeIndex, setActiveIndex] = useState(null);
  const handleAccordionClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  const showExtraPassengersFields = () => {
    if (
      extraPassengers.length > 1 &&
      extraPassengers.length < webSettingData?.max_ticket + 1
    ) {
      return extraPassengers.slice(1)?.map((passenger, index) => (
        <PassengerAccordionWrapper key={index}>
          <PassengerHeader
            color={webSettingData?.buttontextcolor}
            background={webSettingData?.buttoncolor}
            onClick={() => handleAccordionClick(index)}
          >
            <span>{`Passenger - ${index + 2}`}</span>
            <StyledFontAwesomeIcon
              icon={activeIndex === index ? faSortUp : faSortDown}
              className="accordion-icon"
            />
          </PassengerHeader>
          <AccordionContent isOpen={activeIndex === index}>
            <SingleTravellerInformation
              passengerIndex={index + 2}
              values={values}
              handleChange={handleChange}
              countries={countries}
            />
          </AccordionContent>
        </PassengerAccordionWrapper>
      ))
    }
  };

  const allBookingInformation = {
    totalprice: fareSummery?.grandTotal,
    passengerInformation: {...passengerInformation,dob:dateForm(passengerInformation?.dob),passport_expiry:dateForm(passengerInformation?.passport_expiry)},
    values: values,
    setValues: setValues,
    paymentStutas: paymentStutas,
    paymentGateway: paymentGatewayNumber,
    fareSummry: fareSummery,
    searchInfo: searchInfo,
    bookingInfo: bookingInfo,
    journeyInfo: journeyInfo,
    returnSearchInfo: returnSearchInfo,
    returnFirstJourneyInfo: returnFirstJourneyInfo,
    returnAmmount: returnAmmount,
    regularAmmount: regularAmmount,
    discountValue: discountValue?.discount,
    subTripid: subTripid,
    journeyInfoTax: journeyInfoTax,
    bookingInfoTax: bookingInfoTax,
    token: token,
  };
  
  const handleTicketTracking = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/tickets/bookingid/${id}`
    );
    const result = await response.json();

    if (result?.status === "success") {
      dispatch(ticketTracking(result?.data));
      history.push("/ticket-traking");
      toast.success("success");
    }
  };

  const handleSubmitPayment = async (paymentStutas) => {
    if (paymentStutas === "Pay Latter") {
      setLoading(true);
      PaymentHelper(allBookingInformation, dispatch)
        .then((res) => {
          setLoading(false);
          handleTicketTracking(res?.data?.booking_id);
        })
        .catch((err) => console.log(err));
    } else if (!paymentGateway) {
      toast.error("select payment gateway");
      return;
    }
  };

  const minDates = moment().subtract(100, 'years').toDate();
  const maxDates = moment().toDate();

  return (
    <>
      <TravelarInformation>
        <InformationHeader>
          {languageData?.checkout_page_title[webSettingData?.language]}
        </InformationHeader>
        <PassengerAccordionWrapper>
          <PassengerHeader
            color={webSettingData?.buttontextcolor}
            background={webSettingData?.buttoncolor}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span>{`Passenger - 1`}</span>
            <StyledFontAwesomeIcon
              icon={isOpen ? faSortUp : faSortDown}
              className="accordion-icon"
            />
          </PassengerHeader>
          <AccordionContent isOpen={isOpen}>
            <Grid>
            <FlexCol>
                <label htmlFor="title">
                  {languageData?.title[webSettingData?.language]}*
                </label>
                <Select
                  name={`title`}
                  id={`title`}
                  onChange={(e) =>
                    setPassengerInformation({
                      ...passengerInformation,
                      title: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select Title</option>            
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Mdm">Mdm</option>
                  <option value="Ms">Ms</option>
                  <option value="Mstr">Mstr</option>
                  <option value="Dr">Dr</option>
                  <option value="Prof">Prof</option>
                  <option value="Others">Others</option>
                </Select>
              </FlexCol>
              <FlexCol>
                <label htmlFor="name">
                  {languageData?.first_name[webSettingData?.language]}*
                </label>

                <TextField
                  id={`name`}
                  name={`name`}
                  type="text"
                  placeholder={languageData?.first_name[webSettingData?.language]}
                  value={passengerInformation?.first_name}
                  onChange={(e) =>
                    setPassengerInformation({
                      ...passengerInformation,
                      first_name: e.target.value,
                    })
                  }
                  required
                />
              </FlexCol>

              <FlexCol>
                <label htmlFor="surName">
                  {languageData?.last_name[webSettingData?.language]}*
                </label>
                <TextField
                  type="text"
                  placeholder={languageData?.last_name[webSettingData?.language]}
                  name={`surName`}
                  value={passengerInformation?.last_name}
                  onChange={(e) =>
                    setPassengerInformation({
                      ...passengerInformation,
                      last_name: e.target.value,
                    })
                  }
                  required
                />
              </FlexCol>

              <FlexCol>
                <label htmlFor="dob">
                  {languageData?.dob[webSettingData?.language]}*
                </label>
                <Calendars
                 id="dob"
                 name="dob"
                 value={passengerInformation?.dob}
                 onChange={(date)=>setPassengerInformation({
                  ...passengerInformation,
                  dob: date,
                })
                 }
                 required
                 minDate={minDates}
                 maxDate={maxDates}
                 clearIcon={null}
                 dateFormat="YYYY-MM-DD"
                 valueDefault={null}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                />
                {/* <TextField
                  id="dob"
                  name="dob"
                  type="date"
                  value={passengerInformation?.dob}
                  onChange={(e) =>
                    setPassengerInformation({
                      ...passengerInformation,
                      dob: e.target.value,
                    })
                  }
                  required
                  min={moment().subtract(100, "years").format("YYYY-MM-DD")}
                  max={new Date().toISOString().split("T")[0]}
                /> */}
              </FlexCol>
              
              <FlexCol>
                <label htmlFor="gender">
                  {languageData?.gender[webSettingData?.language]}*
                </label>
                <Select
                  name={`gender`}
                  id={`gender`}
                  onChange={(e) =>
                    setPassengerInformation({
                      ...passengerInformation,
                      gender: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Select>
              </FlexCol>

              <FlexCol>
                <label htmlFor="NRIC">
                  {
                    languageData?.checkout_page_nid_title[
                      webSettingData?.language
                    ]
                  }*
                </label>

                <FlexRow>
                  <Select
                    name={`id_type`}
                    id={`id_type`}
                    onChange={(e) =>
                      setPassengerInformation({
                        ...passengerInformation,
                        id_type: e.target.value,
                      })
                    }
                  >
                    <option value="NRIC">NRIC</option>
                    <option value="Passport">PP</option>
                  </Select>
                  <TextField
                    id="NRIC"
                    name={`passPort`}
                    type="text"
                    placeholder={
                      languageData?.checkout_page_nid_input_text[
                      webSettingData?.language
                      ]
                    }
                    value={passengerInformation?.id_number}
                    onChange={(e) =>
                      setPassengerInformation({
                        ...passengerInformation,
                        id_number: e.target.value,
                      })
                    }
                  />
                </FlexRow>
              </FlexCol>

              <FlexCol>
                <label htmlFor="passport_expiry">
                  {languageData?.passport_expiry[webSettingData?.language]}
                </label>
                <Calendars
                 id="passport_expiry"
                 name="passport_expiry"
                 value={passengerInformation?.passport_expiry}
                 onChange={(date)=>setPassengerInformation({
                  ...passengerInformation,
                  passport_expiry: date,
                })
                 }
                 minDate={maxDates}
                 clearIcon={null}
                 dateFormat="YYYY-MM-DD"
                 valueDefault={null}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                />
                {/* <TextField
                  id="passport_expiry"
                  name="passport_expiry"
                  type="date"
                  value={passengerInformation?.passport_expiry}
                  onChange={(e) =>
                    setPassengerInformation({
                      ...passengerInformation,
                      passport_expiry: e.target.value,
                    })
                  }
                /> */}
              </FlexCol>

              <FlexCol>
                <label htmlFor="countryName">
                  {
                    languageData?.checkout_page_country_title[
                    webSettingData?.language
                    ]
                  }
                </label>
                {/* {token ? (
                  <ReactSelect
                    options={countries}
                    name="country_id"
                    value={countries[getCountry()]}
                    onChange={(selectOption) =>
                      setPassengerInformation({
                        ...passengerInformation,
                        country_id: selectOption.id,
                      })
                    }
                  />
                ) : ( */}
                  <CountrySelect
                    name="country_id"
                    id="country_id"
                    style={{width:'100%'}}
                    onChange={handleCountryChange}
                  >
            <option value="">Select Country</option>

                    {countryName?.map((item, index) => (
                      <option
                        value={item.id}
                        key={index}
                        selected={item?.id == passengerInformation?.country_id}
                      >
                        {item.name}
                      </option>
                    ))}
                  </CountrySelect>
                {/* )}  */}
              </FlexCol>
            </Grid>
          </AccordionContent>
        </PassengerAccordionWrapper >

        {/* end Nationality */}
        {showExtraPassengersFields()}
        {/*  */}
        <PaymentWrapper>
          <Payment
            handleSubmitPayment={handleSubmitPayment}
            paymentStutas={paymentStutas}
            setPaymentStutas={setPaymentStutas}
            paymentGateway={paymentGateway}
            setpaymentGateway={setpaymentGateway}
            passengerInformation={passengerInformation}
            allBookingInformation={allBookingInformation}
            setLoading={setLoading}
          />
        </PaymentWrapper>
      </TravelarInformation>
    </>
  );
};

export default TravellerInformation;

// *1100*14#
