import DatePicker from "react-date-picker";
import Select from "react-select";
import styled from "styled-components";
import Button from "../../../bootstrap/Button";
import TextField from "../../../bootstrap/TextField";
import NavItem from "../../../bootstrap/NavItem";

export const Form = styled.form`
  padding: 20px 20px;
`;
export const InnerForm = styled.div`
  .react-date-picker__inputGroup__input:invalid {
    background: transparent;
  }
  .input-group {
    border: none;
  }
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;
export const Location = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 425px) {
    gap: 10px;
    flex-direction: column;
  }
`;
export const LocationInput = styled(TextField)`
  background: white;
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 17px 15px;
`;
export const DateInput = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 425px) {
    gap: 10px;
    flex-direction: column;
  }
`;
export const Calendar = styled(DatePicker)`
  display: block;
  .react-date-picker__wrapper {
    button {
      svg {
        stroke: #808080 !important;
      }
    }
  }
  .react-date-picker__inputGroup {
    font-size: 14px;
    input {
      color: #808080 !important;
    }
  }
  .react-date-picker__inputGroup__divider {
    font-size: 0;
  }

  .react-date-picker__inputGroup__divider:before {
    content: "-";
    font-size: 12px;
    color: #808080 !important;
  }
  .react-date-picker__calendar-button svg {
    stroke: #808080 !important;
  }
  input {
    color: #808080 !important; /* Replace with your desired color */
  }
`;

export const JourneyDate = styled.div`
  flex: 1;
  background: white;
  border: 1px solid #d9d9d9;
  padding: 2px 2px 2px 15px;
  .react-date-picker__wrapper {
    border: none;
  }
`;

export const ReturnDate = styled.div`
  flex: 1;
  padding: 2px 2px 2px 15px;
  background: white;
  border: 1px solid #d9d9d9;
  .react-date-picker__wrapper {
    border: none;
  }
`;

export const DateText = styled.div`
  font-size: 10px;
  text-transform: capitalize;
`;

export const SearchBtnArea = styled.div`
  text-align: right;
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;
export const SearchBtnAreaFindTrip = styled.div`
  text-align: right;
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
export const SearchBtn = styled(Button)`
  text-transform: capitalize;
  ${(props) =>
    `background: ${props.searchbtnbgcolor};
  color: ${props.btntextcolor};
  border: none;
  padding: 10px 30px;
  margin-left: auto;
  font-size: 14px;
  transition: 0.4s;
  &&:hover {
    background: ${props.searchbtnbghvcolor};
  }`}
`;

export const ExchangePhoto = styled.svg`
  border: 1px solid #d9d9d9;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  left: 23.3%;
  top: 23%;
  background: white;
  z-index: 2;
  width: 15px;
  height: 15px;
  ${(props) => `
  color: ${props.headercolor};
  
  `}
  @media (max-width: 1024px) {
    left: 48.5%;
    top: 15%;
  }
  @media (max-width: 992px) {
    left: 48.38%;
    top: 15%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

// start booking page

export const BookingPageExchangePhoto = styled.div`
  border: 1px solid #d9d9d9;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  left: 18.9%;
  top: 35%;
  background: white;
  z-index: 2;
  @media (max-width: 1024px) {
    left: 48.6%;
    top: 16%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const BookingPageExchangePhotoTwo = styled(BookingPageExchangePhoto)`
  border: none;
`;

export const BookingPageInnerForm = styled.div`
  .input-group {
    border: none;
  }
  display: grid;
  grid-template-columns: 38% 38% auto;
  grid-gap: 10px;
  @media (max-width: 1024px) {
    grid-template-columns: 100%;
  }
`;
export const BookingPageLocation = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
    grid-gap: 10px;
  }
`;

export const BookingLocationInput = styled(TextField)`
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 15px;
`;
export const BookingPageDate = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
    grid-gap: 10px;
  }
`;
export const BookingPageJourneyDate = styled.div`
  background: white;
  border: 1px solid #d9d9d9;
  padding: 2px 2px 2px 15px;
  .react-date-picker__wrapper {
    border: none;
  }
`;
export const BookingPageReturnDate = styled.div`
  background: white;
  border: 1px solid #d9d9d9;
  padding: 2px 2px 2px 15px;
  .react-date-picker__wrapper {
    border: none;
  }
`;
export const BookingPageSearchBtn = styled(Button)`
  text-transform: capitalize;
  ${(props) =>
    `background: ${props.searchbtnbgcolor};
  color: ${props.btntextcolor};
  border: none;
  padding: 10px 30px;
  margin-top:0;
  margin-left: auto;
  font-size: 14px;
  transition: 0.4s;
  &&:hover {
    background: ${props.searchbtnbghvcolor};
  }`}
`;
// export const SearchLocation = styled(Select)`
//   font-size: 14px;
//   text-transform: capitalize;
//   flex: 1;
//   > div {
//     border-radius: 0%;
//     border: 1px solid #d9d9d9;
//     padding-left: 12px;
//     min-height: 50px;
//     cursor: pointer;
//   }
//   input {
//     padding: 11px 0px;
//   }

//   > div > div:last-child {
//   }

//   > div > div:last-child > div {
//   }
// `;
export const SearchLocation = styled.input`
  font-size: 14px;
  text-transform: capitalize;
  flex: 1;
  border: 1px solid #d9d9d9;
  padding: 11px 8px 11px 15px;
  outline: none;
  width: 100%; /* Ensures the input takes up the full width of the container */
        box-sizing: border-box;
  
`;

export const LocationWrapper = styled.div`
  left: 3%;
  width: 96%;
  top: 45px;
  bottom: auto;
  position: absolute;
  display: none;
  background-color: #ffffff;
  z-index: 4;
  border: 1px solid #cccccc;
  border-top: none;
`;

export const LocationUl = styled.ul`
max-height: 200px;
padding: 0 0 0 4px;
margin: 4px 4px 4px 0;
position: relative;
overflow-x: hidden;
overflow-y: auto;
-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
`;

export const RadioInput = styled.div`
  display: flex;
  margin-bottom:10px;
  @media (max-width: 425px) {
    gap: 10px;
    flex-direction: column;
  }
`;

export const SinglePayment = styled(NavItem)`
  margin-right: 20px;
  display: flex;
  align-items: center;
  // width:50%;
  label {
    text-transform:capitalize;
    font-size:14px;
  }
  input[type='radio']:checked {
    accent-color: #2b3087;
  }
`;
export const PaymentInput = styled.input`
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 8px 0 0;
  cursor: pointer;
`;
export const Label = styled.label`
  cursor: pointer;
`;
