import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../bootstrap/Button";
import { months } from "moment";
import Background from '../../../src/assets/images/caret-down-solid.svg'


export const Container = styled.div`
  font-size: 14px;
`;
export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
`;
export const FormWrapper = styled.div`
  padding: 100px 50px;
  @media (max-width: 576px) {
    padding: 50px 0px;
  }
`;
export const FormLogo = styled.img`
  width: 145px;
  height: 66px;
  @media (max-width: 576px) {
    width: 110px;
    height: 50px;
  }
`;
export const InnerFormWrapper = styled.div`
  width: 400px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 0 auto;
  padding: 40px;
  @media (max-width: 576px) {
    padding: 20px;
    width: 300px;
  }
  @media (max-width: 420px) {
    padding: 20px;
    width: 250px;
  }
`;
export const LogoArea = styled.div`
  text-align: center;
  img {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
`;
export const Form = styled.form``;
export const FormHeader = styled.h5`
  text-align: center;
  text-transform: capitalize;
  color: #363636;
  font-size: 18px;
  // padding-top: 15px;
  // margin-bottom: 1rem;
`;
export const SubHeader = styled.p`
  color: #2b3087;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
`;
export const InputField = styled.div`
  label {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }

  width: 100%;
  margin-bottom: 0.75rem;
  position: relative;
  /* svg {
    width: 20px;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  } */
`;

export const SingupLink = styled(Link)`
  text-transform: capitalize;
  text-decoration: none;
  color: ${(props) => props.textcolor};
  margin-left: 5px;
`;
export const Extra = styled.div`
  padding: 20px 0px;
`;
export const CheckBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  input {
    cursor: pointer;
  }
`;
export const Account = styled.label`
  font-size: 12px;
  margin-left: 5px;
  cursor: pointer;
`;
export const ConditionLink = styled(Link)`
  margin-left: 5px;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 13px;
  color: ${(props) => props.textcolor};
`;
export const SignupButton = styled(Button)`
  text-transform: capitalize;
  ${(props) => ` 
 border: none;
  padding: 12px 0px;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  background: ${props.btnbgcolor};
  color: ${props.btntextcolor};
  transition: 0.4s;

  &:hover {
    background: ${props.btnbghvcolor};
  }
  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }
`}
`;
export const NameWrapper = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;
export const FirstNameAndNid = styled.div`
  display: flex;
  margin-bottom: 15px;
`;
export const Select = styled.select`
  padding: 0.575rem 0.75rem .575rem .6rem;
  border: none;
  color: #6c757d;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #eaeaea;
  background-color: transparent;
  outline: none;
  text-transform: capitalize;
  -webkit-appearance: none;
  background :url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") 95% no-repeat;
  background-size: 12px;
  // padding-left:20px;
  border-radius:0;
  &&:focus-visible {
    position: relative;
    z-index:1;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
  @media (max-width:768px)
  {
    border-right: 0px solid transparent;
    background-size: 10px;
  }
`;
export const Months = styled.option`
  text-transform: capitalize;
`
export const PasswordWrapper = styled.div`
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

export const CountryCodeSelect = styled.div`
  height: 100%;
  max-width: 8rem;
 
  background: #f0f0f0;
  color: #6c757d;;
  border: 1px solid rgb(234, 234, 234);
  padding: 0 0.75rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 2rem;
    height: 1rem;
    // aspect-ratio: 16/ 9;
    object-fit: cover;
  }

  span {
    // margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
  }

  @media(max-width:762px){
    // padding: 0.62rem 0.75rem;
    font-size:.7rem;
    img{
      width:60%;
    }
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ModalHeaderButton = styled.div`
  width: 1.5rem;
  aspect-ratio: 1/ 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor:pointer;
  }
`;

export const ModalHeaderTitle = styled.h1`
  flex-grow: 1;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  text-transform: capitalize;
  // color: red;
`;

export const ScrollWrapper = styled.div`
height:300px;

  margin: .5rem 0;
  padding:1rem 0;
  position:relative;
`;

export const SCCountryCodeList = styled.ul`
  margin:0;
  padding: 0;
  position:absolute;
  max-height: 100%;
  overflow: auto;
  top:0;
  left:0;
  width:100%;
  // padding-inline-start: 40px;
`;

export const SCCountryCodeItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid rgb(234, 234, 234);

  img {
    width: 2rem;
    aspect-ratio: 16/ 9;
    object-fit: cover;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const PhoneWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 1rem;

  & > input,
  & > select {
    height: 100%;
  }

  Select {
    padding: 9.2px 25px 9.2px 9.2px;
    max-width: 100%;
  }
  @media(max-width:762px){
    grid-template-columns: 30% 70%;
    gap:0;
    select {
      padding: 9.2px 18px 9.2px 9.2px;
    }
  }
`;
export const ErrrorMsg = styled.div`
  color: red;
`;
export const CountrySelect = styled(Select)`
  padding: 10px;
  width: 100%;
  border-right: 1px solid #eaeaea;
  background-color: transparent;
`;

export const TypeSelect = styled(Select)`
  padding: 10px;
  width: 100%;
  border-right: 1px solid #eaeaea;
  background-color: transparent;
`;

export const DOBInput = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  @media(max-width:762px){
    grid-template-columns: 25% 42% 33%;
    gap:0;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media only screen and (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PasswordField = styled.div`
  position: relative;

  svg {
    width: 20px;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  }
`;

export const ErrorMsg = styled.span`
  display: block;
  color: #ee0000;
  padding-top: 0.5rem;
`;
