import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import PaymentForm from "./paymentForm";
import axios from "axios";
import { toast } from "react-toastify";
import Countdown from "./Timer";

const StripeForm = ({payment,type,amount}) => {
  const history = useHistory();
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  const { bookingIds } = useSelector((state) => state.busLists);
  // const payment = history?.location?.state?.info?.payment;

  // const [type, setType] = useState("");

  useEffect(() => {
    setStripePromise(
      loadStripe(
        process.env.REACT_APP_STRIPE_KEY
      )
    );
  }, []);

  const getClientSecret = async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_MODULE_DOMAIN}/tickets/payments`,
        formData
      );

      const clientSecret = response.data.clientSecret;
      setClientSecret(clientSecret);
    } catch (error) {
      toast.error(error?.message || "Something went wrong !");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // const amt = history?.location?.state?.info?.amount;
    // setType(history?.location?.state?.info?.type);
    const data = { amount: amount };
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    getClientSecret(formData);
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Countdown onEnd={() => history.push("/")} />
      </div>
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <div>
            <PaymentForm type={type} payment={payment}/>
          </div>
        </Elements>
      )}
    </>
  );
};

export default StripeForm;
