import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Container from "../../bootstrap/Container";
import Layout from "../../bootstrap/Layout";
import Row from "../../bootstrap/Row";
import Spinner from "../../bootstrap/Spinner";
import Contact from "../../components/Ui/Contact";
import PassengersCheckList from "../../components/Ui/PassengersCheckList";
import SideBar from "../../components/Ui/SideBar";
import TravellerInformation from "../../components/Ui/TravellerInformation";
import languageData from "../../lib/lang.config.json";
import checkoutLogo from "../../photo/bus-pav.jpg";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import {
  CheckoutBody,
  LeftSide,
  LeftSideHeader,
  LeftSideWrapper,
  RightSide,
  SpinnerWrapper,
} from "./Checkout.styles.js";
import instance from "../../axiosInstance";
import { useHistory } from "react-router";

const Checkout = () => {
  const { webSettingData } = useSelector((state) => state.busLists);
  const [width, setWidth] = useState();
  const [discountValue, setDiscountValue] = useState(null);
  const [subTripid, setSubtripid] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentStutas, setPaymentStutas] = useState("");
  const [passengerInformation, setPassengerInformation] = useState({
    address: "",
    city: "",
    country_id: "",
    first_name: "",
    id_number: "",
    id_type: "",
    last_name: "",
    login_email: "",
    login_mobile: "",
    slug: "",
    status: "",
    user_id: "",
    zip_code: "",
    title: "",
    passport_expiry: "",
  });
  const [isRegesteredUser, setRegesteredUser] = useState(false);
  const [token, setToken] = useState("");
  const [returnAmmount, setReturnAmmount] = useState(null);
  const [regularAmmount, setRegularAmmount] = useState(null);
  const [payPalClientId, setPayPalPaymentId] = useState(null);
  const [userProfileInfo, setUserProfileInfo] = useState(null);
  const [values, setValues] = useState({});
  const [paymentGateway, setpaymentGateway] = useState("");
  const innerWidth = window.innerWidth;
  const history = useHistory();

  useEffect(() => {
    setWidth(innerWidth);
  }, [width]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [token]);

  useEffect(() => {
    setLoading(true);

    if (token) {
      setTimeout(() => {
        instance
          .get(`${process.env.REACT_APP_API_MODULE_DOMAIN}/passangers/info`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const result = response.data;
            if (result?.status === "success") {
              setLoading(false);
              setPassengerInformation(result?.data);
              setUserProfileInfo(result?.data);
              localStorage.setItem(
                "userProfileInfo",
                JSON.stringify(result?.data)
              );
            } else {
              history.push({
                pathname: "/login",
                state: { from: history.location },
              });
            }
          })
          .catch((error) => {
            // Handle error here
            setLoading(false);
          });
      }, 1000);
    }
  }, [token]);

  useEffect(() => {
    setReturnAmmount(JSON.parse(localStorage.getItem("return")));
    setRegularAmmount(JSON.parse(localStorage.getItem("regular")));
  }, [discountValue, passengerInformation, subTripid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    var ele = document.querySelectorAll("input[type='radio']:checked");
    for (let index = 0; index < ele.length; index++) {
      ele[index].checked = false;
    }
    setPaymentStutas("");
    setpaymentGateway("");
  };

  useEffect(() => {
    localStorage.setItem(
      "passengerInformation",
      JSON.stringify(passengerInformation)
    );
  }, [passengerInformation]);
  useEffect(() => {
    try {
      const userProfileInfo = JSON.parse(
        localStorage.getItem("userProfileInfo")
      );
      setUserProfileInfo(userProfileInfo);
    } catch (error) {
      console.error("Error parsing or retrieving userProfileInfo:", error);
    }
  }, []);
  return (
    <Layout
      title="Checkout"
      description="This is checkout page"
      userProfileInfo={userProfileInfo}
    >
      {loading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          <Container>
            <CheckoutBody>
              <Row>
                <LeftSide lg="9" md="12">
                  <LeftSideWrapper>
                    {/* <LeftSideHeader>
                      <img src={checkoutLogo} alt="Checkout Logo" />
                      <div>
                        {
                          languageData?.checkout_page_sub_title[
                          webSettingData?.language
                          ]
                        }
                      </div>
                    </LeftSideHeader> */}

                    <Contact
                      passengerInformation={passengerInformation}
                      setPassengerInformation={setPassengerInformation}
                      values={values}
                      setValues={setValues}
                      handleChange={handleChange}
                      setRegesteredUser={setRegesteredUser}
                      token={token}
                      paymentStutas={paymentStutas}
                      setPaymentStutas={setPaymentStutas}
                    />
                    {/* end contact */}

                    <TravellerInformation
                      isRegesteredUser={isRegesteredUser}
                      setPassengerInformation={setPassengerInformation}
                      passengerInformation={passengerInformation}
                      values={values}
                      setValues={setValues}
                      handleChange={handleChange}
                      discountValue={discountValue}
                      setDiscountValue={setDiscountValue}
                      returnAmmount={returnAmmount}
                      regularAmmount={regularAmmount}
                      subTripid={subTripid}
                      token={token}
                      setLoading={setLoading}
                      paymentStutas={paymentStutas}
                      setPaymentStutas={setPaymentStutas}
                      paymentGateway={paymentGateway}
                      setpaymentGateway={setpaymentGateway}
                    />
                    {/* end travellerInformation */}
                  </LeftSideWrapper>
                  {/* end formArea */}
                {width < 922 && (<PassengersCheckList />)}
                    
                  {width < 922 && (
                    <SideBar
                      discountValue={discountValue}
                      setDiscountValue={setDiscountValue}
                      subTripid={subTripid}
                      setSubtripid={setSubtripid}
                    />
                  )}

                  {width > 922 && (<PassengersCheckList />)}
                  {/* end checkList */}
                </LeftSide>
                <RightSide lg="3" md="12">
                  {width > 922 && (
                    <SideBar
                      discountValue={discountValue}
                      setDiscountValue={setDiscountValue}
                      subTripid={subTripid}
                      setSubtripid={setSubtripid}
                    />
                  )}
                  
                </RightSide>
              </Row>
            </CheckoutBody>
          </Container>
        </>
      )}
      {/* </PayPalScriptProvider> */}
    </Layout>
  );
};

export default Checkout;
