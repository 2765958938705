import { Link } from "react-router-dom";
import styled from "styled-components";
import NavItem from "../../../../bootstrap/NavItem";
import Ul from "../../../../bootstrap/Ul";

export const Wrapper = styled.div`
  background: ${(props) => props.bg};
  text-align: center;
  height: auto;
`;
export const HeaderBottomWrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  align-items: center;
  @media (max-width: 992px) {
    padding: 0px;
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
  }
`;
export const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  img {
    width: 70%;
    height: 70%;
    object-fit: contain;
  }
`;
export const NavigationWrapper = styled.div`
  margin-left: auto;
  @media (max-width: 992px) {
    margin: 0;
  }
`;
export const NavigationUl = styled(Ul)`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  display: none;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
`;
export const NavigationWithOutRes = styled(Ul)`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;

  @media (max-width: 992px) {
    display: none;
  }
`;
export const SingleNavItem = styled(NavItem)`
  list-style: none;
`;

export const DropdownContainer = styled.div`
  display: inline-block;
  position: relative;
`;

export const DropdownButton = styled.button`
  background-color: transparent;
  border: none;
  text-transform: capitalize;
  text-decoration: none;
  color: #fff;
  padding: 16px 15px;
  margin-inline: auto;
  font-weight: 300;
  font-size: 16px;
  border-bottom: 3px solid transparent;
  transition: 0.4s;
  cursor: pointer;
  font-family: inherit;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  &&:hover {
    border-bottom: 3px solid #fff;
    padding: 16px 15px;
  }
`;

export const CaretDownIcon = styled.img`
  width: 1rem;
  aspect-ratio: 1/ 1;
  overflow: hidden;
`;

export const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;

  display: ${(props) => (props.open ? "block" : "none")};
`;

export const DropdownItem = styled.li`
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const NavLink = styled(Link)`
  text-transform: capitalize;
  text-decoration: none;
  color: #fff;
  padding: 16px 15px;
  font-weight: 300;
  font-size: 16px;
  border-bottom: 3px solid transparent;
  transition: 0.4s;
  display: block;
  &&:hover {
    border-bottom: 3px solid #fff;
    padding: 16px 15px;
  }
`;

export const DropDownNavLink = styled(Link)`
  text-align: left;
  text-transform: capitalize;
  color: var(--primary-100, #2b3087);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  padding: 0.5rem 1rem;
  display: block;
  &&:hover {
    border: none;
    border-bottom: none;
  }
`;

export const NavLinkId = styled.a`
  text-decoration: none;
  color: #fff;
  padding: 16px 15px;
  font-weight: 300;
  font-size: 16px;
  border-bottom: 3px solid transparent;
  transition: 0.4s;
  display: block;
  &&:hover {
    border-bottom: 3px solid #fff;
    padding: 16px 15px;
  }
`;
export const NavigationBar = styled.div`
  display: none;
  text-align: left;
  @media (max-width: 992px) {
    display: inline-block;
    align-items: center;
  }
`;
export const NavigationBarIcon = styled.img`
  width: 35px;
  height: 40px;
`;
