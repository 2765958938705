import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Countdown = ({ minutes = 5, onEnd }) => {
  // Retrieve the remaining seconds from sessionStorage or default to minutes * 60
  const initialSeconds = parseInt(sessionStorage.getItem("remainingSeconds"));
  const [seconds, setSeconds] = useState(initialSeconds);
  const history = useHistory();
  // decrement seconds
  useEffect(() => {
    if (seconds > 0) {
      const intervalId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      // clean up interval on re-render
      return () => clearInterval(intervalId);
    } else {
      onEnd();
    }
  }, [seconds, onEnd]);

  // Store the remaining seconds in sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem("remainingSeconds", seconds.toString());
  }, [seconds]);

  // format time
  const minutesDisplay = Math.floor(seconds / 60);
  const secondsDisplay = seconds % 60;

  return (
    <div>
      Time left: {minutesDisplay}:{secondsDisplay < 10 ? "0" : ""}
      {secondsDisplay}
    </div>
  );
};

export default Countdown;
