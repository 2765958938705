import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import android from "../../../../assets/images/android.png";
import apple from "../../../../assets/images/ios-1.png";
import Container from "../../../../bootstrap/Container/";
import languageData from "../../../../lib/lang.config.json";
import ScrollUp from "../../ScrollUP";
import {
  About,
  Address,
  CommonNavItem,
  Download,
  DownloadHeader,
  FooterBottomWrapper,
  FooterIcon,
  InnerAbout,
  InnerPrivacy,
  Privacy,
  SocialImage,
  Wrapper,
} from "./FooterBottom.styles.js";

const FooterTop = () => {
  const { webSettingData, contactInfoData, appDataAction } = useSelector(
    (state) => state.busLists
  );

  return (
    <Wrapper bgColor={webSettingData?.footercolor}>
      <Container>
        <FooterBottomWrapper>
          <Address>
            Phone: {contactInfoData?.contact} <br />
            {contactInfoData?.opentime} <br /> {contactInfoData?.address}
          </Address>

          <About>
            <InnerAbout>
              <CommonNavItem hvcolor={webSettingData?.buttontextcolor}>
                <Link to="/about">
                  {
                    languageData?.footer_top_about_button[
                    webSettingData?.language
                    ]
                  }
                </Link>
              </CommonNavItem>
              {/* <CommonNavItem hvcolor={webSettingData?.buttontextcolor}>
                <Link to="/blog">
                  {
                    languageData?.footer_top_blog_button[
                      webSettingData?.language
                    ]
                  }
                </Link>
              </CommonNavItem> */}
              <CommonNavItem hvcolor={webSettingData?.buttontextcolor}>
                <Link to="/tours">
                  tours
                </Link>
              </CommonNavItem>
              <CommonNavItem hvcolor={webSettingData?.buttontextcolor}>
                <Link to="/attractions">
                  attraction
                </Link>
              </CommonNavItem>
              {/* <CommonNavItem hvcolor={webSettingData?.buttontextcolor}>
                <Link to="/faq">
                  {
                    languageData?.footer_top_FAQ_button[
                    webSettingData?.language
                    ]
                  }
                </Link>
              </CommonNavItem> */}
              <CommonNavItem hvcolor={webSettingData?.buttontextcolor}>
                <Link to="/contact-us">
                  {
                    languageData?.footer_top_contact_button[
                    webSettingData?.language
                    ]
                  }
                </Link>
              </CommonNavItem>
            </InnerAbout>
          </About>

          <Privacy>
            <InnerPrivacy>
              <CommonNavItem hvcolor={webSettingData?.buttontextcolor}>
                <Link to="/privacy">
                  {
                    languageData?.footer_top_privacy_button[
                    webSettingData?.language
                    ]
                  }
                </Link>
              </CommonNavItem>
              {/* <CommonNavItem hvcolor={webSettingData?.buttontextcolor}>
                <Link to="/cookies">
                  {
                    languageData?.footer_top_cookies_button[
                    webSettingData?.language
                    ]
                  }
                </Link>
              </CommonNavItem> */}
              <CommonNavItem hvcolor={webSettingData?.buttontextcolor}>
                <Link to="/terms-and-condition">
                  {
                    languageData?.footer_top_terms_button[
                    webSettingData?.language
                    ]
                  }
                </Link>
              </CommonNavItem>
            </InnerPrivacy>
          </Privacy>

          {/* <Download>
            <DownloadHeader>
              {languageData?.footer_top_download_app[webSettingData?.language]}
            </DownloadHeader>
            <FooterIcon>
              <a
                href={appDataAction?.button_one_link}
                target="_blank"
                rel="noreferrer"
              >
                <SocialImage img={apple} alt="android" />
              </a>
              <a
                href={appDataAction?.button_two_link}
                target="_blank"
                rel="noreferrer"
              >
                <SocialImage img={android} alt="apple" />
              </a>
            </FooterIcon>
            <ScrollUp />
          </Download> */}
        </FooterBottomWrapper>
      </Container>
    </Wrapper>
  );
};

export default FooterTop;
