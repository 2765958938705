import styled from "styled-components";
import DatePicker from "react-date-picker";
import TextField from "../../../bootstrap/TextField";
import Select from "react-select";
import NavItem from "../../../bootstrap/NavItem";
import Slider from "react-slick";
import SinglePhoto from "../../../components/Ui/SinglePhoto";


export const Wrapper = styled.div`
  background: #f8f8f8;
  // min-height: calc(100vh - 362px);
`;

export const InnerBody = styled.div`
  display: flex;
  padding: 20px 0;
  gap:30px;
  align-items:center;
  @media (max-width:992px){
    flex-direction: column;
    gap:0;
  }
  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;
export const LeftSide = styled.div`
  width:50%;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  @media (max-width: 992px) {
    width:100%;
  }
  
`;
export const HeaderWrapper = styled.div`
  padding: 10px;
  background: #2b3087;
  color: white;
 
`;
export const DetailWrapper = styled.div`
  padding: 20px;
  background: #FFFFFF;
  
  @media (max-width: 768px) {
    padding: 10px;
  }
`;
export const RightSide = styled.div`
width:50%;
  // padding-left: 50px;
  @media (max-width: 992px) {
    margin-top: 40px;
    width:100%;
    // padding-left: 10px;
  }
  @media (max-width: 768px) {
    display:none;
  }

`;
export const Form = styled.form`
  display:flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  @media (max-width: 1024px) {
    grid-template-columns: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex; 
  justify-content: center;
`;

export const SubmitBtn = styled.input`
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 80%;
  margin: 10px auto auto;
  background: ${(props) => props.btnBgColor};
  color: ${(props) => props.btnTextColor};
  transition: 0.4s;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
  &&:hover {
    background: ${(props) => props.btnBgHvColor};
  }
`;

export const RightSideTitle = styled.h2`
  margin: 0px;
  font-size: 30px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
export const RightSideSubTitle = styled.h4`
  margin: 0px;
  font-size: 18px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const DateInput = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 425px) {
    gap: 10px;
    flex-direction: column;
  }
`;
export const RadioInput = styled.div`
  display: flex;
  
  @media (max-width: 425px) {
    gap: 10px;
    flex-direction: column;
  }
`;
export const Calendar = styled(DatePicker)`
  display: block;
  .react-date-picker__wrapper {
    button {
      svg {
        stroke: #808080 !important;
      }
    }
  }
  .react-date-picker__inputGroup {
    font-size: 14px;
    input {
      color: #808080 !important;
    }
  }
  .react-date-picker__inputGroup__divider {
    font-size: 0;
  }

  .react-date-picker__inputGroup__divider:before {
    content: "-";
    font-size: 12px;
    color: #808080 !important;
  }
  .react-date-picker__calendar-button svg {
    stroke: #808080 !important;
  }
  input {
    color: #808080 !important; /* Replace with your desired color */
  }
`;

export const JourneyDate = styled.div`
  flex: 1;
  background: white;
  border: 1px solid #d9d9d9;
  padding: 2px 2px 2px 15px;
  .react-date-picker__wrapper {
    border: none;
  }
`;

export const ReturnDate = styled.div`
  flex: 1;
  padding: 2px 2px 2px 15px;
  background: white;
  border: 1px solid #d9d9d9;
  .react-date-picker__wrapper {
    border: none;
  }
`;

export const DateText = styled.div`
  font-size: 10px;
  text-transform: capitalize;
`;


export const LocationInput = styled(TextField)`
  background: white;
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 17px 15px;
`;

export const SearchLocation = styled(Select)`
  font-size: 14px;
  text-transform: capitalize;
  flex: 1;
  > div {
    border-radius: 0%;
    border: 1px solid #d9d9d9;
    padding-left: 12px;
    min-height: 50px;
    cursor: pointer;
  }
  input {
    padding: 11px 0px;
  }

  > div > div:last-child {
  }

  > div > div:last-child > div {
  }
`;
export const SinglePayment = styled(NavItem)`
  margin-right: 20px;
  display: flex;
  align-items: center;
  width:50%;
  label {
    text-transform:capitalize;
    font-size:14px;
  }
  input[type='radio']:checked {
    accent-color: #2b3087;
  }
`;
export const PaymentInput = styled.input`
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 8px 0 0;
  cursor: pointer;
`;
export const Label = styled.label`
  cursor: pointer;
`;

export const StyledSlider = styled(Slider)`
  position: relative;
  // margin: -10px;
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-slide {    
    float: left;
    height: 100%;
    border-radius: 10px;
    overflow:hidden;
    // width: 25% !important;
    min-height: 1px;
    @media (min-width:1024px)
    {
      width: 25% !important;
    }
  }
  .slick-active {
    &:first-child {
      margin-left: 0px;
    }
  }
  .slick-prev {
    width: 25px;
    height: 25px;
    padding: 3px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    background: white;
    z-index: 1;
    left: 16px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      width: 8px;
      transform: rotate(180deg);
    }
  }
  .slick-next {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    background: white;
    z-index: 1;
    right: 15px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      width: 8px;
      display: block;
    }
  }
`;

export const SliderWrapper = styled.div`
  background: #f8f8f8;
  padding-bottom: 30px;
  position: relative;

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;
export const InnerJournyCard = styled.div`
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px;
    @media (max-width: 768px) {
    border-radius:5px;
    overflow:hidden;
  }
`;
export const PhotoAndLocation = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
export const Image = styled(SinglePhoto)`
  width: 100%;
  height: 100%;
  object-fit: fill;
`;





export const FromWrapper = styled.div`
border: 1px solid #CECECE;
background: #FAFAFA;
border-radius: 5px;
padding: 5px 15px;
label {
  font-size: 12px;
  text-transform: uppercase;
  color: #555555;
  font-weight: 300;
}
`;

export const InputGrop = styled.div`
position: relative;
    display: table;
    border-collapse: separate;
    .homeSearchText {
      padding: 0 !important;
      border: none !important;
      background: #FAFAFA !important;
      box-shadow: none !important;
  }
  .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}
`;