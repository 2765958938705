import React from "react";

const Input = ({ placeholder, type, value, isChecked, ...styles }) => {
  return (
    <div>
      {type === "checkbox" ? (
        <input
          type={type}
          checked={isChecked}
          {...styles}
        />
      ) : (
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          {...styles}
        />
      )}
    </div>
  );
};

export default Input;
