import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Countdown from "./Timer";
import DbsPaymentForm from "./dbsPaymentForm";

const DbsForm = ({type,payment}) => {
  const history = useHistory();

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Countdown onEnd={() => history.push("/")} />
      </div>
          <div>
            <DbsPaymentForm type={type} payment={payment}/>
          </div>
    </>
  );
};

export default DbsForm;
