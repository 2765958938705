const busReducer = (state = [], action) => {
  switch (action.type) {
    case "ADD_BUS_NAME": {
      const newState = {
        ...state,
        busLists: action.payload,
      };
            return newState;
    }
    case "ADD_ERROR": {
      const newState = {
        ...state,
        error: action.payload,
      };
      return newState;
    }
    case "SEARCH_INFO-STORE": {
      const newState = {
        ...state,
        searchInfoStore: action.payload,
      };
      return newState;
    }
    case "TRICKET_TRACKING": {
      const newState = {
        ...state,
        ticketTracking: action.payload,
      };
      return newState;
    }
    case "WEB-SETTING": {
      const newState = {
        ...state,
        webSettingData: action.payload,
      };
      return newState;
    }
    case "CONTACT-INFO": {
      const newState = {
        ...state,
        contactInfoData: action.payload,
      };
      return newState;
    }
    case "LANGUAGE": {
      const newState = {
        ...state,
        languageData: action.payload,
      };
      return newState;
    }
    case "APP-DATA-ACTION": {
      const newState = {
        ...state,
        appDataAction: action.payload,
      };
      return newState;
    }
    case "GET-REVEIW": {
      const newState = {
        ...state,
        reveiws: action.payload,
      };
      return newState;
    }

    case "REGULAR-BOOKING-INFORMATION": {
      const newState = {
        ...state,
        regularBookingInformation: action.payload,
      };
      return newState;
    }

    case "UPDATE-FARE-SUMMERY": {
      const newState = {
        ...state,
        fareSummery: action.payload,
      };
      return newState;
    }

    case "SAVE-BOOKING-IDS": {
      const newState = {
        ...state,
        bookingIds: Array.isArray(state.bookingIds)
          ? [...state.bookingIds, action.payload]
          : [action.payload],
      };
      return newState;
    }
    case "SAVE-ATTRACTION-BOOKING-IDS": {
      const newState = {
        ...state,
        bookingAttractionIds: Array.isArray(state.bookingAttractionIds)
          ? [...state.bookingAttractionIds, action.payload]
          : [action.payload],
      };
      return newState;
    }

    case "CLEAR-BOOKING_IDS": {
      const newState = {
        ...state,
        bookingIds: [],
      };
      return newState;
    }
    case "CLEAR-ATTRACTION-BOOKING_IDS": {
      const newState = {
        ...state,
        bookingAttractionIds: [],
      };
      return newState;
    }
    case "ATTRACTION_DETAILS": {
      const newState = {
        ...state,
        attractionData: action.payload
      }
      return newState;
    }
    case "ATTRACTION_BOOKING": {
      const newState = {
        ...state,
        attractionBooking: action.payload
      }
      return newState;
    }
    case "OPERN_MODEL": {
      const newState = {
        ...state,
        openModelState: action.payload
      }
      return newState;
    }
    case "IS_LOADING": {
      const newState = {
        ...state,
        isLoadings: action.payload
      }
      return newState;
    }
    default: {
      return state;
    }
  }
};

export default busReducer;
