import axios from "axios";

// Create an instance of Axios
const instance = axios.create();

// Add a response interceptor
instance.interceptors.response.use(
    (response) => {
        if (
            response &&
            response.data &&
            response.data.status === "fail" &&
            response.data.data === "token not valid"
        ) {
            // Redirect the user to the login screen
            localStorage.removeItem("token");
            localStorage.removeItem("transtar_site_new_user_session");
            localStorage.removeItem("transtar_site_new_user_name");
            localStorage.removeItem("transtar_site_new_user_id");
            localStorage.removeItem("transtar_site_new_user_phone");
            localStorage.removeItem("transtar_site_new_user_email");
            // window.location.href = "/login"; // Change this URL to your login page
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;
