const initialState = {
    userProfileDetails: null,
    userNewProfileDetails: null
  };
  
  const userProfileDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_USER_PROFILE_DETAILS": {
        return {
          ...state,
          userProfileDetails: action.payload,
        };
      }
      case "SET_USER_NEW_PROFILE_DETAILS": {
        return {
          ...state,
          userNewProfileDetails: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default userProfileDetailsReducer;
  