import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextField from "../../../bootstrap/TextField/index.jsx";
import languageData from "../../../lib/lang.config.json";
import IconArrowBack from "../../../assets/images/icon-arrow-back.svg";
import Modal from "../../../components/Ui/Modal";
import {
  ContactInputWrapper,
  ContactText,
  ContactWrapper,
  ContentInputs,
  CountryCodeSelect,
  InputLabel,
  Select,
  UserContactWrapper,
} from "./Contact.styles.js";
import {  ModalHeader, ModalHeaderButton, ModalHeaderTitle, SCCountryCodeItem, SCCountryCodeList, ScrollWrapper } from "../../../pages/Signup/Signup.styles.js";

const Contact = ({ setPassengerInformation, passengerInformation }) => {
  const { webSettingData } = useSelector((state) => state.busLists);
  const [openModel, setOpenModel] = useState(false);
  const [countryName, setCountryName] = useState([]);
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const [country, setCountry] = useState({
     flag: `https://flagcdn.com/${(passengerInformation?.country_iso)?.toLowerCase()}.svg`,
    countryCode: passengerInformation?.country_code,
  });
  const [filteredCountryList, setFilteredCountryList] = useState(countryName);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/countries`)
      .then((res) => res.json())
      .then((data) => {setCountryName(data.data);setFilteredCountryList(data.data);setCountry((prev) => ({
    ...prev,
     flag: `https://flagcdn.com/${(passengerInformation?.country_iso)?.toLowerCase()}.svg`,
    countryCode: passengerInformation?.country_code,
  }));});    
  }, []);

   const openModelHandeler = () => {
    setOpenModel(true);
  }
  const onClose = () => {
    setOpenModel(false);
  setFilteredCountryList(countryName);

  };

  const handleCountrySelect = (country) => {
    setPassengerInformation({ ...passengerInformation, country_code: country?.phonecode });
  setCountry((prev) => ({
    ...prev,
    flag: `https://flagcdn.com/${(country?.iso).toLowerCase()}.svg`,
    countryCode: country?.phonecode.startsWith("+")
    ? country?.phonecode
    : `+${country?.phonecode}`,
  }));
  onClose();
  };

  const filterCountryList = (event) => {
  const value = event.target.value;

  const filtered = countryName.filter((country) => {
    const countryCode = `${country?.phonecode}`;

    return (
      country?.name?.toLowerCase().includes(value.toLowerCase()) ||
      countryCode.includes(value)
    );
  });

  setFilteredCountryList(filtered);
  };


  return (
    <>
      <ContactWrapper>
        <label htmlFor="">
          {
            languageData?.checkout_page_contact_details_text[
            webSettingData?.language
            ]
          }
        </label>
        <ContentInputs>
          <ContactInputWrapper>
            <InputLabel>
              <label htmlFor="country_code">
                Mobile No*
              </label>
            </InputLabel>
            <UserContactWrapper>
            <CountryCodeSelect onClick={() => {openModelHandeler();}}>
                      {passengerInformation?.country_iso &&  <img src={country?.flag == "https://flagcdn.com/undefined.svg"
                        ? `https://flagcdn.com/${(passengerInformation?.country_iso)?.toLowerCase()}.svg` : country?.flag} />}
                        <span>{country?.countryCode ? country?.countryCode : passengerInformation?.country_code}</span>
                  </CountryCodeSelect>
                  <Modal
                  isOpen={openModel} onClose={onClose}>
                  <ModalHeader>
                      <ModalHeaderButton onClick={onClose}>
                        <img src={IconArrowBack} alt="" />
                      </ModalHeaderButton>
                      <ModalHeaderTitle>Select Country Code</ModalHeaderTitle>
                    </ModalHeader>
                    <TextField
                    name="contry_code"
                    type="text"
                    // value={values?.confirm_email}
                    placeholder="Search By Country or Country Code"
                    onChange={filterCountryList}
                    autoFocus={true}
                    autoComplete="off"
                  />
                  <ScrollWrapper> 
                    <SCCountryCodeList>
                      {filteredCountryList.map((country, index) => (
                        <SCCountryCodeItem
                          key={index}
                          onClick={() => handleCountrySelect(country)}
                        >
                          <img
                            src={`https://flagcdn.com/${(country?.iso).toLowerCase()}.svg`}
                            // alt={`${country?.name?.common} Flag`}
                          />
                          <span>
                            {country?.name}{" "}
                                  {country?.phonecode.startsWith("+")
                                  ? `(${country?.phonecode})`
                                  : `(+${country?.phonecode})`}
                          </span>
                        </SCCountryCodeItem>
                      ))}
                    </SCCountryCodeList> 
                  </ScrollWrapper>
                </Modal>
              {/* <Select
                name="country_code"
                id="country_code"
                onChange={(e) =>
                  setPassengerInformation({
                    ...passengerInformation,
                    country_code: e.target.value,
                  })
                }
              >
                {countryName.map((item) => (
                  <option
                    value={item?.phonecode}
                    key={item?.id}
                    selected={
                      item?.phonecode === passengerInformation?.country_code
                    }
                  >
                    {getFlagEmoji(item?.iso)}
                          {" "}
                    {item?.phonecode.startsWith("+")
                      ? item?.phonecode
                      : `+${item?.phonecode}`}
                  </option>
                ))}
              </Select> */}

              <TextField
                min="0"
                name="login_mobile"
                type="number"
                onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                }
                placeholder={
                  "Mobile No"
                }
                value={passengerInformation?.login_mobile}
                onChange={(e) =>
                  setPassengerInformation({
                    ...passengerInformation,
                    login_mobile: e.target.value,
                  })
                }
                style={{ border: "0px solid #eaeaea" }}
              />
            </UserContactWrapper>
          </ContactInputWrapper>
          <ContactInputWrapper>
            <InputLabel>
              <label htmlFor="email">
                Email
              </label>
            </InputLabel>
            <TextField
              id="email"
              name="login_email"
              type="email"
              placeholder={
                languageData?.checkout_page_contact_details_input_email[
                webSettingData?.language
                ]
              }
              value={passengerInformation?.login_email}
              onChange={(e) =>
                setPassengerInformation({
                  ...passengerInformation,
                  login_email: e.target.value,
                })
              }
            />
          </ContactInputWrapper>
        </ContentInputs>

        <ContactText>
          {languageData?.checkout_page_text[webSettingData?.language]}
        </ContactText>
      </ContactWrapper>
    </>
  );
};

export default Contact;
