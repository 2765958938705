import DatePicker from "react-date-picker";
import styled from "styled-components";

export const Container = styled.div``;
export const TravelarInformation = styled.div`
  font-size: 14px;
`;
export const InformationHeader = styled.h4`
  text-transform: capitalize;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  color: #2b2b2b;
  // padding-bottom: 15px;
`;

export const Calendars = styled(DatePicker)`
.react-date-picker__inputGroup__input:invalid {
  background: transparent;
}
display: block;
.react-date-picker__wrapper {
  border:thin solid #EAEAEA;
  padding:.575rem .75rem;
  button {
    svg {
      stroke: #808080 !important;
    }
  }
}
.react-date-picker__inputGroup {
  font-size: 14px;
  input {
    color: #808080 !important;
    
  }
}
.react-date-picker__inputGroup__divider {
  font-size: 0;
}

.react-date-picker__inputGroup__divider:before {
  content: "-";
  font-size: 12px;
  color: #808080 !important;
}
.react-date-picker__calendar-button svg {
  stroke: #808080 !important;
}
input {
  color: #808080 !important; /* Replace with your desired color */
}
`;

export const Select = styled.select`
  padding: 0.575rem 0.75rem;
  border: none;
  border-right: 1px solid #eaeaea;
  &&:focus-visible {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
`;

export const Address = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: 18% 79%;
  grid-gap: 3%;
  margin-top: 20px;
  @media (max-width: 576px) {
    text-align: left;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10px;
    margin-bottom: 20px;
  }
  label {
    text-transform: capitalize;
  }
`;
export const Country = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: 18% 38%;
  grid-gap: 3%;
  margin-top: 20px;
  @media (max-width: 576px) {
    text-align: left;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10px;
    margin-bottom: 20px;
  }
  label {
    text-transform: capitalize;
  }
`;
export const CheckboxWrapper = styled.div`
  text-align: left;
  margin-top: 20px;
`;
export const Label = styled.label`
  margin-left: 5px;
  cursor: pointer;
`;

export const SingleTravellerInformationWrapper = styled.div`
  margin-top: 40px;
`;
export const Input = styled.input`
  cursor: pointer;
`;
export const CityAndZip = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 4%;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media (max-width: 576px) {
    grid-template-columns: 100%;
  }
`;

export const Password = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: 18% 38%;
  grid-gap: 3%;
  margin-top: 20px;
  @media (max-width: 576px) {
    text-align: left;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10px;
    margin-bottom: 20px;
  }
`;
export const PasswordLabel = styled.label`
  cursor: pointer;
  margin-left: 5px;
`;
export const PayPalWrapper = styled.div`
  z-index: 3;
  margin-top: 30px;
`;

export const TypeSelect = styled.select`
  padding: 10px;
  width: 100%;
  background-color: transparent;
  border: none;
  border: 1px solid #eaeaea;
  outline: none;
  text-transform:capitalize;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 20px;
  column-gap: 2.5rem;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    box-sizing: border-box;
  }
`;

export const FlexCol = styled.div`
display: grid;
grid-template-columns: 40% 60%;
// flex-direction: column;
align-items: center;
// gap: 0.5rem;

.input-group-text {
  padding: 0;
  border-right: 1px solid #eee;
}

.input-group {
  width: 99%;
}

label {
  text-transform: capitalize;
  // width: 50%;
}

@media (max-width: 767px) {
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
}
  // display: flex;
  // flex-direction: row;
  // gap: 10px;
  // // padding: 0 10px;
  // align-items: center;
  // label {
  //   width : 50%;
  // }
  // select {
  //   width : 100%;
  // }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PaymentWrapper = styled.div`
  // margin-top: 3rem;
  @media (max-width: 767px) {
    // margin-top: 16rem;
  }
`;
export const PassengerAccordionWrapper = styled.div`
  border-radius: 5px;
  transition: max-height 1s ease-in-out;
  height: auto;
`;
export const PassengerHeader = styled.div`
  padding: 0.5rem;
  text-transform: capitalize;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  transition: max-height 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props) => ` 
    color: ${props.color};
    background: ${props.background};
  `}

  &:hover {
    cursor: pointer;
  }
`;
export const AccordionContent = styled.div`
  width: 100%;
  max-width: 100%;
  padding: ${(props) => (props.isOpen ? "0 0 20px" : "0px")};
  overflow: ${(props) => (props.isOpen ? 'visible' : "hidden")};
  height: ${(props) => (props.isOpen ? "auto !important" : "0px")};
  transition: max-height 1s ease-in-out;
`;
