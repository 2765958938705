export const addBusName = (payload) => {
  return {
    type: "ADD_BUS_NAME",
    payload,
  };
};

export const addError = (payload) => {
  return {
    type: "ADD_ERROR",
    payload,
  };
};

export const SearchInfoStore = (payload) => {
  return {
    type: "SEARCH_INFO-STORE",
    payload,
  };
};

export const ticketTracking = (payload) => {
  return {
    type: "TRICKET_TRACKING",
    payload,
  };
};

export const webSetting = (payload) => {
  return {
    type: "WEB-SETTING",
    payload,
  };
};

export const contactInfo = (payload) => {
  return {
    type: "CONTACT-INFO",
    payload,
  };
};

export const language = (payload) => {
  return {
    type: "LANGUAGE",
    payload,
  };
};

export const appDataAction = (payload) => {
  return {
    type: "APP-DATA-ACTION",
    payload,
  };
};
export const getReveiw = (payload) => {
  return {
    type: "GET-REVEIW",
    payload,
  };
};

export const regularBookingInformation = (payload) => {
  return {
    type: "REGULAR-BOOKING-INFORMATION",
    payload,
  };
};

export const updateFareSummery = (payload) => {
  return {
    type: "UPDATE-FARE-SUMMERY",
    payload,
  };
};

export const saveBookidIds = (payload) => {
  return {
    type: "SAVE-BOOKING-IDS",
    payload,
  };
};
export const saveAttractionBookidIds = (payload) => {
  return {
    type: "SAVE-ATTRACTION-BOOKING-IDS",
    payload,
  };
};

export const clearSavedBookIds = (payload) => {
  return {
    type: "CLEAR-BOOKING_IDS",
    payload,
  };
};
export const clearSavedAttractionBookIds = (payload) => {
  return {
    type: "CLEAR-ATTRACTION-BOOKING_IDS",
    payload,
  };
};

export const attractionDetails=(payload)=>{
  return{
    type:"ATTRACTION_DETAILS",
    payload,
  }
}
export const attractionBookingInfo=(payload)=>{
  return{
    type:"ATTRACTION_BOOKING",
    payload,
  }
}
export const openModel =(payload)=>{
  return{
    type:"OPERN_MODEL",
    payload,
  }
}
export const isLoading = (payload) => {

  return {
      type: "IS_LOADING",
      payload,
  };
};