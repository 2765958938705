import moment from "moment";

export const dateForm = (date) => {
  const selectedDate = new window.Date(date);
  const fullYear = selectedDate.getFullYear();
  const month = selectedDate.getMonth() + 1;
  const day = selectedDate.getDate();

  return `${fullYear}-${month}-${day}`;
};

export const getLocation = (item, boardingAndDroppingPoint) => {
  const drop_location_id = item?.drop_location_id;
  const pick_location_id = item?.pick_location_id;
  const pick = boardingAndDroppingPoint.find(
    (pickLocation) => pickLocation?.id === pick_location_id
  );
  const drop = boardingAndDroppingPoint.find(
    (dropLocation) => dropLocation?.id === drop_location_id
  );

  return `${pick?.name} - ${drop?.name}`;
};

export const getMaxValue = (array) => {
  return array?.reduce(function (prev, current) {
    return Math.max(prev, current.adult_fair);
  }, 0);
};

export const getMinValue = (array) => {
  var len = array.length,
    min = Infinity;
  while (len--) {
    if (Number(array[len].adult_fair) < min) {
      min = Number(array[len].adult_fair);
    }
  }
  return min;
};

export const compareTime = (busObj) => {
  const { busType, arrivalTime, departureTime, filterBus, durationArray } =
    busObj;
  const arrivalArray = [];
  const departureArray = [];
  const array = [];
  const format = "hh:mm:ss";

  if (arrivalTime) {
    for (let i = 0; i < durationArray?.length; i++) {
      const start = durationArray[i].start;
      const end = durationArray[i].end;
      const startTime = moment(start, format);
      const endTime = moment(end, format);
      for (let j = 0; j < filterBus?.length; j++) {
        const item = filterBus[j];
        const Time = convertTime12to24(item.end_time);
        const arrivalTime = moment(Time, format);
        if (arrivalTime.isBetween(startTime, endTime)) {
          arrivalArray.push(item);
        }
      }
    }
    return arrivalArray;
  } else if (departureTime) {
    for (let i = 0; i < durationArray?.length; i++) {
      const start = durationArray[i].start;
      const end = durationArray[i].end;
      const startTime = moment(start, format);
      const endTime = moment(end, format);
      for (let j = 0; j < filterBus?.length; j++) {
        const item = filterBus[j];
        const Time = convertTime12to24(item.start_time);
        const arrivalTime = moment(Time, format);
        if (arrivalTime.isBetween(startTime, endTime)) {
          departureArray.push(item);
        }
      }
    }
    return departureArray;
  } else if (busType) {
    for (let i = 0; i < durationArray?.length; i++) {
      const item = durationArray[i];
      for (let j = 0; j < filterBus?.length; j++) {
        const element = filterBus[j];
        if (element.fleet_id === item) {
          array.push(element);
        }
      }
    }
    return array;
  }
};

export const convertTime12to24 = (time12h) => {
  if(!time12h){
    return;
  }
  const [time, modifier] = time12h.split(" ");

  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};

export const getPicLocation = (location, values) => {
  return location.findIndex((locationName) => {
    return String(locationName.id) === String(values.pick_location);
  });
};

export const getDropLocation = (location, values) => {
  return location.findIndex((locationName) => {
    return String(locationName.id) === String(values.drop_location);
  });
};

export const isRegurnDateValid = (date) => date?.split("-").includes("NaN");

export const toUpperFirst = (string) =>
  string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();

export const FirstLetterUppercase = (text) => {
  const arr = text.split(" ");

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const str2 = arr.join(" ");
  return str2;
};

const api_url = `${process.env.REACT_APP_API_MODULE_DOMAIN}/website/seetings`;

export const getSettingsData = async () => {
  const response = await fetch(api_url);
  var data = await response.json();
  return data;
};

export const busType = (fleet, fleetId) => {
  const result = fleet?.find((item) => item?.id === fleetId);
  return result?.type;
};

export const getAmPm = (date) => {
  const h =  new Date(date).getHours();
  return (h > 12) ? ' PM' : ' AM';
}

export const getFlagEmoji = (countryCode) => {
  const codePoints = countryCode && countryCode
      .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt());
  return codePoints ? String.fromCodePoint(...codePoints) : '';
}

export const getQueryParamValue = (name) => {
  const urlParams = window.location.search.substring(1);
  const params = urlParams.split('&');

  for (let i = 0; i < params.length; i++) {
    const param = params[i].split('=');
    if (param[0] === name) {
      return param[1] === undefined ? true : decodeURIComponent(param[1]);
    }
  }
};

export const setQueryParamValue = (name, value) => {
  const url = window.location.href;
  const urlParts = url.split('?');
  if (urlParts.length >= 2) {
    const baseUrl = urlParts[0];
    const queryString = urlParts[1];
    const queryParams = queryString.split('&');
    let newQueryString = '';
    let paramExists = false;

    for (let i = 0; i < queryParams.length; i++) {
      const param = queryParams[i].split('=');
      if (param[0] === name) {
        paramExists = true;
        if (value !== undefined) {
          newQueryString += `${name}=${value}&`;
        }
      } else {
        newQueryString += `${queryParams[i]}&`;
      }
    }

    if (!paramExists && value !== undefined) {
      newQueryString += `${name}=${value}&`;
    }

    newQueryString = newQueryString.replace(/&$/, ''); // Remove trailing '&' if present

    window.history.replaceState(null, null, `${baseUrl}?${newQueryString}`);
  } else {
    window.history.replaceState(null, null, `${url}?${name}=${value}`);
  }
};

export const removeQueryParam = (paramName) => {
  const url = window.location.href;
  const [baseUrl, queryString] = url.split('?');

  if (!queryString) return; // No query string to modify

  const params = queryString.split('&');

  const newParams = params.filter(param => {
    const [key] = param.split('=');
    return key !== paramName;
  });

  const newQueryString = newParams.join('&');

  const newUrl = newQueryString ? `${baseUrl}?${newQueryString}` : baseUrl;
  window.history.replaceState({}, '', newUrl);
};


export function generateDates(startDate) {
  const startingDate = new window.Date(startDate);
  const dates = [new Date(startingDate)]; // Initialize array with the starting date
  for (let i = 1; i <= 6; i++) {
    const nextDate = new Date(startingDate);
    nextDate.setDate(startingDate.getDate() + i);
    dates.push(nextDate);
  }

  return dates;
}

export function sliderDate(date) {
  const dateObject = new Date(date);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayIndex = dateObject.getDay();
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayName = days[dayIndex];
  const dayOfMonth = dateObject.getDate();
  const monthIndex = dateObject.getMonth();
  const monthName = months[monthIndex];
  return `${dayOfMonth} ${monthName}, ${dayName}`;
}

export const formatDate = (dates) => {
  const date = new Date(dates);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const getDateTwoDaysLater = (date) => {
  const currentDate = new Date(date);
  currentDate.setDate(currentDate.getDate() + 2);
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};