import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import fetchSeatData from "../../../helpers/fetch-seat-data";
import languageData from "../../../lib/lang.config.json";
import NewPayPal from "../PaymentGateway/NewPayPal";
import Paystack from "../PaymentGateway/Paystack";
import Razorpay from "../PaymentGateway/Razorpay";
import StripeCheckoutBtn from "../PaymentGateway/StripeCheckoutBtn";
import {
  BookTicketButton,
  ButtonWrapper,
  CancelButton,
  Label,
  PaymentAndButton,
  PaymentButton,
  PaymentInput,
  PaymentMethod,
  PaymentUl,
  SinglePayment,
} from "./Payment.styles";
import {
  checkForNullValues,
  groupObjects,
  payLatter,
} from "../PaymentGateway/payLatter";
import { store } from "../../../redux/store";
import moment from "moment";

const getJsonDataForPayments = (allBookingInformation) => {
  const {
    passengerInformation,
    values,
    paymentStutas,
    paymentGateway,
    searchInfo,
    bookingInfo,
    token,
  } = allBookingInformation;

  const jsonData = {};

  if (!passengerInformation.first_name) {
    // Handle the error here, e.g., throw an exception or return an error object
    throw new Error("First name is required");
  } else if (paymentStutas === "Pay Now" && !paymentGateway) {
    // Handle the error here, e.g., throw an exception or return an error object
    throw new Error("Please select your payment method");
  }

  jsonData.login_email = passengerInformation.login_email || "";
  jsonData.country_code = passengerInformation.country_code;

  if (!token) {
    jsonData.login_mobile = values?.mobile_country_code.concat(
      passengerInformation?.login_mobile
    );
  } else {
    jsonData.login_mobile = passengerInformation?.login_mobile;
  }

  jsonData.first_name = `${passengerInformation.first_name}`;
  jsonData.last_name = passengerInformation.last_name;
  jsonData.id_type = passengerInformation.id_type || "Nid";
  jsonData.country_id = +passengerInformation.country_id;
  jsonData.id_number = passengerInformation.id_number;
  jsonData.address = passengerInformation.address;
  jsonData.city = passengerInformation.city;
  jsonData.zip_code = passengerInformation.zip_code;
  jsonData.gender = passengerInformation.gender;
  jsonData.id_type = passengerInformation.id_type || "Nid";
  jsonData.dob = passengerInformation.dob;
  jsonData.country = passengerInformation.country_id;
  jsonData.title = passengerInformation.title;
  jsonData.passport_expiry = passengerInformation.passport_expiry || null;
  

  jsonData.trip_id = +bookingInfo?.trip_id;
  jsonData.subtripId = +bookingInfo?.subtripId;
  jsonData.pick_location_id = +searchInfo?.pickLocation;
  jsonData.drop_location_id = +searchInfo?.dropLocation;
  jsonData.pickstand = +bookingInfo?.pickstand;
  jsonData.dropstand = +bookingInfo?.dropstand;
  jsonData.totalprice = +bookingInfo?.totalprice;

  jsonData.aseat = +bookingInfo?.aseat || null;
  jsonData.cseat = +bookingInfo?.cseat || null;
  jsonData.spseat = +bookingInfo?.spseat || null;

  jsonData.journeydate = bookingInfo?.journeydate;
  jsonData.returndate = searchInfo?.returnDate;

  jsonData.paydetail = "This is pay details";
  jsonData.vehicle_id = +bookingInfo?.vehicle_id;
  jsonData.seatnumbers = bookingInfo?.seatnumbers;
  jsonData.totalseat = bookingInfo?.totalseat;

  return jsonData;
};

const Payment = ({
  handleSubmitPayment,
  paymentStutas,
  setPaymentStutas,
  paymentGateway,
  setpaymentGateway,
  passengerInformation,
  allBookingInformation,
  setLoading,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { webSettingData } = useSelector((state) => state.busLists);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [seatsForBooking, setSeatsForBooking] = useState([]);
  const [date, setDate] = useState();

  const getPaymentMethods = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/paymethods`
    );
    const result = await response.json();
    if (result?.status === "success") {
      setPaymentMethods(result?.data);
    }
  };

  const payLatterFN = async (dispatch, allBookingInformation) => {
    try {
      const bookingData = getJsonDataForPayments(allBookingInformation);
      const result = await payLatter(
        bookingData,
        allBookingInformation,
        dispatch
      );
      return result;
    } catch (error) {
      throw new Error(error?.message || "Something went wrong !");
    }
  };

  useEffect(() => {
    try {
      getPaymentMethods();
    } catch (error) {
      console.error("paymentMethods", error);
    }
  }, []);

  const handleCancel = () => {
    history.push("/");
    localStorage.removeItem("bookingInfo");
    localStorage.removeItem("searchInfo");
    localStorage.removeItem("returnFirstJourneyInfo");
    localStorage.removeItem("journeyInfo");
    localStorage.removeItem("discount");
    localStorage.removeItem("regular");
    localStorage.removeItem("return");
    localStorage.removeItem("bookingInfoTax");
    localStorage.removeItem("journeyInfoTax");
    localStorage.removeItem("subtripId");
  };

  useEffect(() => {
    const searchInfo = JSON.parse(localStorage.getItem("searchInfo"));
    setDate(searchInfo?.journeydate);

    if (allBookingInformation?.bookingInfo?.seatnumbers) {
      const _convertToArray =
        allBookingInformation?.bookingInfo?.seatnumbers?.split(",");
      setSeatsForBooking(
        _convertToArray.length
          ? _convertToArray
          : [allBookingInformation?.bookingInfo?.seatnumbers]
      );
    }
  }, []);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    var ele = document.querySelectorAll("input[type='radio']:checked");
    for (let index = 0; index < ele.length; index++) {
      ele[index].checked = false;
    }
    setPaymentStutas("");
  }, [passengerInformation]);
  const handlePaymentMethod = (item) => {
    setPaymentMethods(item?.id);
  };
  const fistName = "fistName";
  const lastName = "lastName";
  const dob = "dob";
  const mobile = "mobile";
  const pymentGatway = "pymentGatway";
  const handleUnpaidPaymentEntry = async (info, type) => {
    const nameRegex = /^[A-Za-z\s]+$/;

    if (!nameRegex.test(passengerInformation?.last_name)) {
      !toast.isActive(lastName) &&
        toast.error(
          "Please enter a valid last name containing only letters and spaces.",
          { toastId: lastName }
        );
      return;
    }
    function isValidMobileNumber(mobileNumber) {
      return /^\d{6,}$/.test(mobileNumber);
    }

    // Example usage:
    if (!isValidMobileNumber(passengerInformation?.login_mobile)) {
      !toast.isActive("mobile") &&
        toast.error("Please enter a valid mobile number.", {
          toastId: "mobile",
        });
      return;
    }

    if (!nameRegex.test(passengerInformation?.first_name)) {
      !toast.isActive(fistName) &&
        toast.error(
          "Please enter a valid first name containing only letters and spaces.",
          { toastId: fistName }
        );
      return;
    }

    const currentDate = new Date(); // Get the current date
    const dobDate = new Date(passengerInformation.dob); // Convert the DOB string to a Date object
    const dobFormatted = moment(dobDate).format("YYYY-MM-DD");
    if (dobDate > currentDate) {
      !toast.isActive("dob") &&
        toast.error("Date of birth cannot be a future date.", {
          toastId: "dob",
        });
      return;
    }
    if (dobFormatted < moment().subtract(100, "years").format("YYYY-MM-DD")) {
      !toast.isActive("dob") &&
        toast.error("Invalid Date Of Birth", {
          toastId: "dob",
        });
      return;
    }
    if (!passengerInformation?.title) {
      !toast.isActive("title") &&
        toast.error("Title is required.", { toastId: "title" });
      return;
    }
    if (!passengerInformation?.dob) {
      !toast.isActive(dob) &&
        toast.error("Please enter a valid date of birth.", { toastId: dob });
      return;
    }
    if (!passengerInformation?.gender) {
      !toast.isActive("gender") &&
        toast.error("Gender is required.", { toastId: "gender" });
      return;
    }
    if (!passengerInformation?.id_number) {
      !toast.isActive("idNumber") &&
        toast.error("Document number is required.", { toastId: "idNumber" });
      return;
    }

    if (paymentGateway === "") {
      !toast.isActive(pymentGatway) &&
        toast.error("Please select a payment method to continue.", {
          toastId: pymentGatway,
        });
      return;
    }
    if (
      !(groupObjects(info.values)?.length >= info.bookingInfo.totalseat - 1)
    ) {
      !toast.isActive("passenger") &&
        toast.error("Other passenger info Required !", {
          toastId: "passenger",
        });
      return;
    }
        const error = checkForNullValues(
      groupObjects(info.values) || [],
      allBookingInformation.passengerInformation.id_number
    );
    if (error) {
      !toast.isActive(error) && toast.error(error, { toastId: error });
      return;
    }
    try {
      setLoading(true);
      sessionStorage.removeItem("remainingSeconds");
            await payLatterFN(dispatch, info);
      setLoading(false);

      if (type == "stripe") {
        const state = store.getState();
        let amount = state?.busLists?.bookingIds?.reduce(
          (accumulator, currentValue) =>
            accumulator + +currentValue?.paidamount,
          0
        );
        const seconds = 300;
        sessionStorage.setItem("remainingSeconds",seconds.toString());
        history.replace({
          pathname: "/payments",
          state: { info: { type: "trip",payment: paymentGateway , amount: amount } },
        });
      }
    } catch (error) {
      history.replace("/booking");
      toast.error(error.message || "Something went wrong try again!");
    }
  };

  if (!allBookingInformation) {
    return <h1>Loading ....</h1>;
  }

  return (
    <>
      <PaymentUl>
        {paymentMethods?.map((item) => (
          <SinglePayment key={item.id}>
            <PaymentInput
              type="radio"
              id={item.name}
              name="payment"
              value={item.name}
              onChange={(e) => setpaymentGateway(e.target.value)}
                          />
            <Label htmlFor={item.name}>{item.name}</Label>
          </SinglePayment>
        ))}
      </PaymentUl>

      <ButtonWrapper>
        <BookTicketButton
          onClick={(e) =>
            handleUnpaidPaymentEntry(allBookingInformation, "stripe")
          }
          type="submit"
          btnbgcolor={webSettingData?.buttoncolor}
          btnbghvcolor={webSettingData?.buttoncolorhover}
          btntextcolor={webSettingData?.buttontextcolor}
        >
          {
            languageData?.tour_booking_page_book_ticket_btn[
              webSettingData?.language
            ]
          }
        </BookTicketButton>
      </ButtonWrapper>
    </>
  );
};

export default Payment;
