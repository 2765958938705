import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import passwordPhoto from "../../assets/login/privacy.svg";
import Container from "../../bootstrap/Container";
import Layout from "../../bootstrap/Layout";
import Spinner from "../../bootstrap/Spinner";
import TextField from "../../bootstrap/TextField/index.jsx";
import languageData from "../../lib/lang.config.json";
import { SpinnerWrapper } from "../Checkout/Checkout.styles";
import IconArrowBack from "../../assets/images/icon-arrow-back.svg";
import Modal from "../../components/Ui/Modal";
import {
  CheckBox,
  Extra,
  ForgetPasswordLink,
  Form,
  FormArea,
  FormHeader,
  InnerFormArea,
  InputField,
  RememberMe,
  SingupLink,
  SubmitButton,
  PhoneWrapper,
} from "./Login.styles.js";
import { LogoArea, FormLogo, Select, CountryCodeSelect, ModalHeader, ModalHeaderButton, ModalHeaderTitle, ScrollWrapper, SCCountryCodeList, SCCountryCodeItem } from "../Signup/Signup.styles";
import instance from "../../axiosInstance";
import { getFlagEmoji } from "../../helpers";
import { setUserNewProfileDetails, setUserProfileDetails } from "../../redux/action/userProfileAction";

export const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { webSettingData } = useSelector((state) => state.busLists);
  const [userProfileInfo, setUserProfileInfo] = useState(null);
  const [passwordType, setPasswordType] = useState(true);
  const [countryName, setCountryName] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [values, setValues] = useState({
    mobile_number: "",
    member_id: "",
    mobile_country_code: "65"
  });
  const [loginData, setLoginData] = useState();
  const [filteredCountryList, setFilteredCountryList] = useState(countryName);
  const [country, setCountry] = useState({
    flag: "https://flagcdn.com/sg.svg",
    countryCode: "+65",
  });

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  useEffect(() => {
    setUserProfileInfo(JSON.parse(localStorage.getItem("userProfileInfo")));
    if(localStorage.getItem("token")) {
      history.push('/')
    }
    fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/countries`)
      .then((res) => res.json())
      .then((data) => {setCountryName(data.data);setFilteredCountryList(data.data);});    
  }, []);

  useEffect(()=>{
    const script = document.createElement('script')
    script.src=`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`
    script.addEventListener('load',()=>{
        window.grecaptcha.ready(()=>{
            window.grecaptcha.execute(process.env.REACT_APP_CAPTCHA_KEY).then((token)=>{setCaptcha(token)})
        })
    })
    document.body.appendChild(script)
  },[])

  const openModelHandeler = () => {
    setOpenModel(true);
  }
  const onClose = () => {
    setOpenModel(false);
  setFilteredCountryList(countryName);

  };

  const handleCountrySelect = (country) => {
  setValues({ ...values, mobile_country_code: country?.phonecode });
  setCountry((prev) => ({
    ...prev,
    flag: `https://flagcdn.com/${(country?.iso).toLowerCase()}.svg`,
    // name: country?.name?.common,
    countryCode: country?.phonecode.startsWith("+")
    ? country?.phonecode
    : `+${country?.phonecode}`,
  }));
  onClose();
  };

  const filterCountryList = (event) => {
  const value = event.target.value;

  const filtered = countryName.filter((country) => {
    const countryCode = `${country?.phonecode}`;

    return (
      country?.name?.toLowerCase().includes(value.toLowerCase()) ||
      countryCode.includes(value)
    );
  });

  setFilteredCountryList(filtered);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const validatePhone = (phone) => {
    const phonePattern = /\b\d{6,14}\b/;
    const isValid = phonePattern.test(phone);
    return isValid;
  };

  const handleTranstarSubmit = async (result) => {
    const script = document.createElement("script");
    script.src =
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`;
    script.addEventListener("load", () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY)
          .then((token) => {
            setCaptcha(token);
          });
      });
    });
    document.body.appendChild(script);
    
    try {
      setIsLoading(true);
      fetch(
        "https://operatorapi.busonlineticket.com/api_service.aspx/LoginOperatorMember",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            profile: "transtar_site_new",
            phone: values?.mobile_number,
            member_id: result?.memberId,
            register_id: "",
            captcha_code: captcha,
            name: ""
        }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
        const login = JSON.parse(data.d);
        if(login?.status == 1) {
          localStorage.setItem("token", result?.data);
        localStorage.setItem("profileMenu",'profile')
        toast.success(result?.message);   
          setLoginData(login);
          // toast.success(`Hello! ${login.user_name}`); 
          dispatch(setUserNewProfileDetails(login))
          // localStorage.setItem("userProfileInfo",data.d);
          localStorage.setItem("transtar_site_new_user_session",login.session_id)
          localStorage.setItem("transtar_site_new_user_id",login.user_id)
          localStorage.setItem("transtar_site_new_user_name",login.user_name)
          localStorage.setItem("transtar_site_new_user_phone",login.user_phone)
          localStorage.setItem("transtar_site_new_user_email",login.user_email)
          setIsLoading(false);             
        if (location.state && location.state.from) {          
          const previousLocation = location.state.from;          
          delete location.state.from;          
          history.replace(previousLocation);
        } else {
          history.replace("/");
        }
        
        setTimeout(() => {
          instance
            .get(`${process.env.REACT_APP_API_MODULE_DOMAIN}/passangers/info`, {
              headers: {
                Authorization: `Bearer ${result?.data}`,
              },
            })
            .then((response) => {
              const result = response.data;
              if (result?.status === "success") {
                const data = result?.data;
                dispatch(setUserProfileDetails(data))
                localStorage.setItem(
                  "userProfileInfo",
                  JSON.stringify(result?.data)
                );
              }
            })
            .catch((error) => {
              console.error("Error fetching passenger information:", error);
            });
        }, 1000);
        } else {
          toast.error('Something went wrong')
        }
          
        
          
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error posting data:", error);
        });
     } catch (error) {
      setIsLoading(false);
      !toast.isActive("error") &&
        toast.error(error, {
          toastId: "error",
        });
      console.error(error, "error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (values?.mobile_country_code === "") {
      !toast.isActive("countryCode") &&
        toast.error("Country code is required.", { toastId: "countryCode" });
      return;
    } else if (values?.mobile_number === "") {
      !toast.isActive("mobile") &&
        toast.error("Phone number is required.", { toastId: "mobile" });
      return;
    } else if (!validatePhone(values?.mobile_number)) {
      !toast.isActive("invalidMobile") &&
        toast.error("Invalid phone number.", { toastId: "invalidMobile" });
      return;
    } else if (values?.password === "") {
      !toast.isActive("password") &&
        toast.error("Password is required.", { toastId: "password" });
      return;
    } else if (values?.password?.length < 6) {
      !toast.isActive("invalidPassword") &&
        toast.error("Password should have at least 6 characters.", {
          toastId: "invalidPassword",
        });
      return;
    }

    const formData = new FormData();
    formData.append("country_code", values?.mobile_country_code);
    formData.append("login_mobile", values?.mobile_number);
    formData.append("password", values?.password);

    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_MODULE_DOMAIN}/passangers/login`,
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      setIsLoading(false);

      if (result?.status === "success") {
        result.memberId ? handleTranstarSubmit(result) : toast.error('Something went wrong');
        
      } else {
        !toast.isActive("fail") &&
          toast.error(result?.message, { toastId: "fail" });
          setPasswordType(true)
      }
    } catch (error) {
      setIsLoading(false);
      !toast.isActive("error") &&
        toast.error(error, {
          toastId: "error",
        });
      console.error(error, "error");
    }
  };

  const handlePassord = () => {
    setPasswordType((prevState) => !prevState);
  };
  
  return (
    <Layout title="Login" userProfileInfo={userProfileInfo}>
      <Container>
        {isLoading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <FormArea>
            <InnerFormArea>
              <LogoArea>
                <FormLogo src={webSettingData?.headerlogo} alt="logo" />
              </LogoArea>

              <Form onSubmit={handleSubmit}>
                <FormHeader>
                  {languageData?.login_page_title[webSettingData?.language]}
                </FormHeader>
                <InputField>
                  <PhoneWrapper>
                  <CountryCodeSelect onClick={() => {openModelHandeler();}}>
                        <img src={country?.flag} alt={`${country?.name}`} />
                        <span>{`${country?.countryCode}`}</span>
                  </CountryCodeSelect>
                  <Modal
                  isOpen={openModel} onClose={onClose}>
                  <ModalHeader>
                      <ModalHeaderButton onClick={onClose}>
                        <img src={IconArrowBack} alt="" />
                      </ModalHeaderButton>
                      <ModalHeaderTitle>Select Country Code</ModalHeaderTitle>
                    </ModalHeader>
                    <TextField
                    name="contry_code"
                    type="text"
                    // value={values?.confirm_email}
                    placeholder="Search By Country or Country Code"
                    onChange={filterCountryList}
                    autoFocus={true}
                    autoComplete="off"
                  />
                  <ScrollWrapper> 
                    <SCCountryCodeList>
                      {filteredCountryList.map((country, index) => (
                        <SCCountryCodeItem
                          key={index}
                          onClick={() => handleCountrySelect(country)}
                        >
                          <img
                            src={`https://flagcdn.com/${(country?.iso).toLowerCase()}.svg`}
                            // alt={`${country?.name?.common} Flag`}
                          />
                          <span>
                            {country?.name}{" "}
                                  {country?.phonecode.startsWith("+")
                                  ? `(${country?.phonecode})`
                                  : `(+${country?.phonecode})`}
                          </span>
                        </SCCountryCodeItem>
                      ))}
                    </SCCountryCodeList> 
                  </ScrollWrapper>
                </Modal>
                    {/* <Select
                      name="mobile_country_code"
                      id="CountryCode"
                      onChange={handleChange}
                    >
                      <option value="" selected>
                        Country Code
                      </option>
                      {countryName?.map((item, index) => ( 
                        <option value={item.phonecode} key={index}>
                          {getFlagEmoji(item?.iso)}
                          {" "}
                          {item?.phonecode.startsWith("+")
                            ? item?.phonecode
                            : `+${item?.phonecode}`}
                        </option>
                      ))}
                    </Select> */}
                    <TextField
                      min="0"
                      name="mobile_number"
                      type="number"
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      placeholder={
                        languageData?.sign_up_page_input_phone[
                          webSettingData?.language
                        ]
                      }
                      alt="ContactIcon"
                      onChange={handleChange}
                    />
                  </PhoneWrapper>
                </InputField>

                <InputField>
                  <TextField
                    name="password"
                    img={passwordPhoto}
                    type={passwordType ? "password" : "text"}
                      placeholder={
                      languageData?.login_page_password_input[
                        webSettingData?.language
                      ]
                      }
                    alt="passwordIcon"
                      onChange={handleChange}
                  />
                  {/* <svg
                    onClick={handlePassord}
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                    </svg> */}
                    <svg
                      onClick={handlePassord}
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                      {passwordType && (
                        <path
                          d="M2 2L22 22"
                          stroke="#000000"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      )}
                    </svg>
                </InputField>
                <Extra>
                  <CheckBox>
                    <input type="checkbox" id="scales" name="scales" />
                    <RememberMe htmlFor="scales">
                      {
                        languageData?.login_page_checkbox_text[
                          webSettingData?.language
                        ]
                      }
                    </RememberMe>
                  </CheckBox>
                  <div>
                    <ForgetPasswordLink
                      to="/forgotpassword"
                      textcolor={webSettingData?.buttoncolor}
                    >
                      {
                        languageData?.login_page_forgot_password_link_text[
                          webSettingData?.language
                        ]
                      }
                    </ForgetPasswordLink>
                  </div>
                </Extra>
                <InputField>
                  <SubmitButton
                    type="submit"
                    btnbgcolor={webSettingData?.buttoncolor}
                    btnbghvcolor={webSettingData?.buttoncolorhover}
                    btntextcolor={webSettingData?.buttontextcolor}
                  >
                    {
                      languageData?.login_page_submit_button[
                        webSettingData?.language
                      ]
                    }
                  </SubmitButton>
                </InputField>
              </Form>
              {/* end form area */}

              <div>
                {
                  languageData?.login_page_question_text[
                    webSettingData?.language
                  ]
                }
                <strong>
                  <SingupLink
                    to="/signup"
                    textcolor={webSettingData?.buttoncolor}
                  >
                    {
                      languageData?.login_page_sign_up_link_text[
                        webSettingData?.language
                      ]
                    }
                  </SingupLink>
                </strong>
              </div>
            </InnerFormArea>
          </FormArea>
        )}
      </Container>
    </Layout>
  );
};
export default Login;
