const initialState = {
  tourGroupDetails: null,
};

const tourGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOUR_GROUP_DETAILS": {
      return {
        ...state,
        tourGroupDetails: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default tourGroupReducer;
