import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
export const BookTicketButton = styled.button`
  display: inline-block;
  text-transform: capitalize;
  border-radius: 0.25rem;
  width:20%;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  font-size:14px;
  font-weight:700;
  margin-top: 1rem;
  ${(props) => ` 
  background: ${props.btnbgcolor};
  color: ${props.btntextcolor};
  transition: 0.4s;
  &&:hover {
    background: ${props.btnbghvcolor};
  }
  @media (max-width:768px)
  {
    width:100%;
  }
`}
`;

export const FlexContainer = styled.div`
  display: flex;
`;
export const Nid = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: 18% 79%;
  grid-gap: 3%;
  margin-top: 20px;
  .input-group-text {
    padding: 0;
    border-right: 1px solid #eee;
  }
  @media (max-width: 576px) {
    text-align: left;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10px;
    margin-bottom: 20px;
  }
  label {
    text-transform: capitalize;
  }
`;
export const TypeSelect = styled.select`
  padding: 10px;
  width: 100%;
  background-color: transparent;
  border: none;
  border: 1px solid #eaeaea;
  outline: none;
  text-transform:capitalize;
  -webkit-appearance: none;
  background :url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") 95% no-repeat;
  background-size: 12px;
  @media (max-width:768px)
  {
    background-size:10px;
  }
`;
export const InputField = styled.div`
  .react-date-picker__inputGroup__input:invalid {
    background: transparent;
  }
  display: grid;
  grid-template-columns: 45% 55%;
  // flex-direction: column;
  align-items: center;
  // gap: 0.5rem;

  .input-group-text {
    padding: 0;
    border-right: 1px solid #eee;
  }

  .input-group {
    width: 99%;
  }
  
  label {
    text-transform: capitalize;
    width: 50%;
  }

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10px;
  }
`;
export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.5rem;
    margin-bottom: 1rem;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    gap:10px;
  }
`;
export const Rows = styled.div`
  display: grid;
  grid-template-columns: 23% 32%;
  grid-gap: 1rem;
    margin-bottom: 1rem;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    gap:10px;
  }
`;
export const Select = styled.select`
  padding: 0.575rem 1.2rem .575rem 0.75rem;
  border: none;
  border: 1px solid #eaeaea;
  // border-right: 0px solid transparent;
  outline: none;
  text-transform:capitalize;
  -webkit-appearance: none;
  border-radius:0;
  background :url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") 95% no-repeat;
  background-size: 12px;
  &&:focus-visible {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
  @media (max-width:768px)
  {
    background-size:10px;
  }
`;
export const Container = styled.div``;
export const TravelarInformation = styled.form`
  font-size: 14px;
  width:100%;
`;
export const InformationHeader = styled.h4`
  text-transform: capitalize;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  color: #2b2b2b;
  padding-bottom: 15px;
  margin: 2rem auto 0;
`;

// export const Select = styled.select`
//   padding: 0.575rem 0.75rem;
//   border: none;
//   border-right: 1px solid #eaeaea;
//   &&:focus-visible {
//     box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
//   }
// `;

export const Address = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: 18% 79%;
  grid-gap: 3%;
  margin-top: 20px;
  @media (max-width: 576px) {
    text-align: left;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10px;
    margin-bottom: 20px;
  }
  label {
    text-transform: capitalize;
  }
`;
export const Country = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: 18% 38%;
  grid-gap: 3%;
  margin-top: 20px;
  @media (max-width: 576px) {
    text-align: left;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10px;
    margin-bottom: 20px;
  }
  label {
    text-transform: capitalize;
  }
`;
export const CheckboxWrapper = styled.div`
  text-align: left;
  margin-top: 20px;
`;
export const Label = styled.label`
  margin-left: 5px;
  cursor: pointer;
`;

export const SingleTravellerInformationWrapper = styled.div`
  margin-top: 40px;
`;
export const Input = styled.input`
  cursor: pointer;
`;
export const CityAndZip = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 4%;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media (max-width: 576px) {
    grid-template-columns: 100%;
  }
`;

export const Password = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: 18% 38%;
  grid-gap: 3%;
  margin-top: 20px;
  @media (max-width: 576px) {
    text-align: left;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 10px;
    margin-bottom: 20px;
  }
`;
export const PasswordLabel = styled.label`
  cursor: pointer;
  margin-left: 5px;
`;
export const PayPalWrapper = styled.div`
  z-index: 3;
  margin-top: 30px;
`;
// ACCORDIAN
export const Accordion = styled.div``;

// export const AccordionItem = styled.details``;

export const AccordionItemHeading = styled.summary`
  padding: 0.5rem;
  text-transform: capitalize;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  transition: max-height 1s ease-in-out;

  ${(props) => ` 
    color: ${props.color};
    background: ${props.background};
  `}

  &:hover {
    cursor: pointer;
  }
`;

export const AccordionItemBody = styled.div``;
export const ErrorButton = styled.div`
  color: #ff0000; // White text color;
  font-size:12px;  
  margin-bottom: 10px;
`;


export const AccordionItem = styled.div`
  // margin-bottom: 10px;
  border-radius: 5px;
  z-index: 1; 
  transition: max-height 1s ease-in-out;  
  height:max-content;
`;


export const AccordionHeader = styled.div`
  padding: 0.5rem;
  text-transform: capitalize;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  transition: max-height 1s ease-in-out;
  display:flex;
  align-items: center;
  justify-content: space-between;

  ${(props) => ` 
    color: ${props.color};
    background: ${props.background};
  `}

  &:hover {
    cursor: pointer;
  }
`;


export const AccordionContent = styled.div`
  // padding: ${(props) => (props.isOpen ? "20px 10px" : "0px")};
  overflow: ${(props) => (props.isOpen ? "unset" : "hidden")};
  height: ${(props) => (props.isOpen ? "max-content !important" : "0")};
  transition: max-height 4s ease-in-out;  
`;
export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  
`;

export const Selection = styled.select`
  padding: 0.73rem 0.2rem;
  border-right: none;
  // width:100%;
  background: transparent;
  border: 1px solid #eaeaea;
  border-radius:5px;
  -webkit-appearance: none;
  background :url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") 95% no-repeat;
  background-size: 12px;
  @media (max-width:768px)
  {
    background-size:10px;
  }
  &&:focus-visible {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    outline:none;
  }
`

export const HotelHeader = styled.div`
  padding: 0.5rem;
  text-transform: capitalize;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  transition: max-height 1s ease-in-out;
  display:flex;
  align-items: center;
  justify-content: space-between;

  ${(props) => ` 
    color: ${props.color};
    background: ${props.background};
  `}

  &:hover {
    cursor: pointer;
  }
`;

export const FlRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  grid-gap: 2.5rem;
    margin-bottom: 1rem;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    gap:10px;
  }
`;

export const Textarea = styled.textarea`
  position: relative;
  flex: 1 1 auto;
  transition: none;
  padding: 0.575rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;

  &:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
`;
