import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledHeading = styled.h2`
  text-transform: uppercase;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  color: #000;
  margin:0;
`;

export const StyledSubHeading = styled.h2`
  text-transform: capitalize;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: #333;
  position: relative;
  overflow: hidden;
  margin: 25px 0;

  &::before,
  &::after {
    content: "";
    width: 50%;
    height: 1px;
    position: relative;
    display: inline-block;
    background-color: #9ca4af;
    vertical-align: middle;
  }

  &::before {
    right: 1.5em;
    margin-left: -50%;
    @media only screen and (max-width: 768px) {
      right: .4em;
    }
  }

  &::after {
    left: 1.5em;
    margin-right: -50%;
    @media only screen and (max-width: 768px) {
      left: .4em;
    }
  }
`;

export const StyledAttractions = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 425px) {
    padding:0 1rem;
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const LoaderContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
`