import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import navigationBarPhoto from "../../../../assets/images/navBar.png";
import CaretDown from "../../../../assets/images/caret-down-solid.svg";
import Container from "../../../../bootstrap/Container/";
import SinglePhoto from "../../SinglePhoto/";
import TrackOrder from "../../TrackOrder";
import {
  HeaderBottomWrapper,
  Logo,
  LogoLink,
  NavigationBar,
  NavigationBarIcon,
  NavigationUl,
  NavigationWithOutRes,
  NavigationWrapper,
  NavLink,
  DropDownNavLink,
  SingleNavItem,
  Wrapper,
  DropdownContainer,
  DropdownButton,
  DropdownList,
  DropdownItem,
  CaretDownIcon
} from "./HeaderBottom.styles.js";

const HeaderBottom = ({ userProfileInfo,title }) => {
  const { webSettingData, languageData } = useSelector(
    (state) => state.busLists
  );
  const {userProfileDetails,userNewProfileDetails} = useSelector((state) => state.userProfileDetailsReducer);
  const [showNavItem, setShowNavItem] = useState(false);
  const [token, setToken] = useState(null);
  const [currency,setCurrency] = useState('S$ (Singapore Dollar)');
  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, [localStorage.getItem("token")]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isCurrencyOpen, setIsCurrencyOpen] = React.useState(false);
  const popupRef = useRef(null);

  const toggleCurrencyDropdown = () => {
    setIsCurrencyOpen(!isCurrencyOpen);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleCurrencyChange = (currency) => {
    setCurrency(currency);
    setIsCurrencyOpen(false);
    localStorage.setItem('currency',currency == "RM (Ringgit Malaysia)" ? 'RM' : 'S$')
  };
  useEffect(() => {
    localStorage.setItem('currency',currency == "RM (Ringgit Malaysia)" ? 'RM' : 'S$')

    const handleScroll = () => {
      if (popupRef.current) {
        popupRef.current.close();
      }
    };

    if (!(/iP(hone|od|ad)/.test(navigator.platform))) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(()=>{
  //   setIsOpen(false)
  // },[popupRef.current])

  return (
    <Wrapper bg={webSettingData?.headercolor}>
      <Container>
        <HeaderBottomWrapper>
          <Logo>
            <LogoLink to="/">
              <SinglePhoto
                img={webSettingData?.headerlogo}
                alt="logo"
                style={{ height: "50px" }}
              />
            </LogoLink>
            <NavigationBar>
              <NavigationBarIcon
                src={navigationBarPhoto}
                alt="navigationIcon"
                onClick={() => setShowNavItem((prevState) => !prevState)}
              />
            </NavigationBar>
          </Logo>
          <NavigationWrapper>
            <NavigationWithOutRes>
              <SingleNavItem>
                <NavLink to="/">
                  {
                    languageData?.navigation_home_button[
                    webSettingData?.language
                    ]
                  }
                </NavLink>
              </SingleNavItem>
              <SingleNavItem>
                <NavLink to="/work">
                  {
                    languageData?.navigation_work_button[
                    webSettingData?.language
                    ]
                  }
                </NavLink>
              </SingleNavItem>
              <DropdownContainer>
                <DropdownButton onClick={toggleDropdown}>Tour Segments
                  <CaretDownIcon src={CaretDown} alt="" />
                </DropdownButton>
                <DropdownList open={isOpen}>
                <DropdownItem>
                    <DropDownNavLink to="/daytours/days">day tour</DropDownNavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <DropDownNavLink to="/tours">tour package</DropDownNavLink>
                  </DropdownItem>
                  
                  <DropdownItem>
                    <DropDownNavLink to="/attractions">attraction</DropDownNavLink>
                  </DropdownItem>
                </DropdownList>
              </DropdownContainer>
              {(title == 'Home' || title == 'Login' || title == 'Signup') && <DropdownContainer>
                <DropdownButton onClick={toggleCurrencyDropdown}>{currency}
                  <CaretDownIcon src={CaretDown} alt="" />
                </DropdownButton>
                <DropdownList open={isCurrencyOpen}>
                  <DropdownItem>
                    <DropDownNavLink onClick={()=>handleCurrencyChange('RM (Ringgit Malaysia)')}>RM (Ringgit Malaysia)</DropDownNavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <DropDownNavLink onClick={()=>handleCurrencyChange('S$ (Singapore Dollar)')}>S$ (Singapore Dollar)</DropDownNavLink>
                  </DropdownItem>
                </DropdownList>
              </DropdownContainer>}
              {/* <SingleNavItem>
                <NavLink to="/blog">
                  {
                    languageData?.navigation_blog_button[
                      webSettingData?.language
                    ]
                  }
                </NavLink>
              </SingleNavItem> */}

              <SingleNavItem>
                <Popup
                  ref={popupRef}
                  trigger={
                    <NavLink to="#">
                      {
                        languageData?.navigation_track_button[
                        webSettingData?.language
                        ]
                      }
                    </NavLink>
                  }
                  position="bottom center"
                >
                  <TrackOrder />
                </Popup>
              </SingleNavItem>

              {token ? (
                <SingleNavItem>
                  <NavLink to="/tickets">{userProfileDetails?.first_name || userProfileDetails?.last_name}</NavLink>
                </SingleNavItem>
              ) : (
                <SingleNavItem>
                  <NavLink to="/login">
                    {
                      languageData?.navigation_login_button[
                      webSettingData?.language
                      ]
                    }
                  </NavLink>
                </SingleNavItem>
              )}
            </NavigationWithOutRes>

            {showNavItem && (
              <NavigationUl>
                <SingleNavItem>
                  <NavLink to="/">
                    {" "}
                    {
                      languageData?.navigation_home_button[
                      webSettingData?.language
                      ]
                    }
                  </NavLink>
                </SingleNavItem>
                <SingleNavItem>
                  <NavLink to="/work">
                    {
                      languageData?.navigation_work_button[
                      webSettingData?.language
                      ]
                    }
                  </NavLink>
                </SingleNavItem>
                {/* <SingleNavItem>
                  <NavLink to="/blog">
                    {" "}
                    {
                      languageData?.navigation_blog_button[
                      webSettingData?.language
                      ]
                    }
                  </NavLink>
                </SingleNavItem> */}
                <DropdownContainer>
                  <DropdownButton onClick={toggleDropdown}>Tour Segments
                    <CaretDownIcon src={CaretDown} alt="" />
                  </DropdownButton>
                  <DropdownList open={isOpen}>
                    <DropdownItem>
                      <DropDownNavLink to="/tours">tours</DropDownNavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <DropDownNavLink to="/attractions">attraction</DropDownNavLink>
                    </DropdownItem>
                  </DropdownList>
                </DropdownContainer>
                {(title == 'Home' || title == 'Login' || title == 'Signup') && <DropdownContainer>
                <DropdownButton onClick={toggleCurrencyDropdown}>{currency}
                  <CaretDownIcon src={CaretDown} alt="" />
                </DropdownButton>
                <DropdownList open={isCurrencyOpen}>
                  <DropdownItem>
                    <DropDownNavLink onClick={()=>handleCurrencyChange('RM (Ringgit Malaysia)')}>RM (Ringgit Malaysia)</DropDownNavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <DropDownNavLink onClick={()=>handleCurrencyChange('S$ (Singapore Dollar)')}>S$ (Singapore Dollar)</DropDownNavLink>
                  </DropdownItem>
                </DropdownList>
              </DropdownContainer>}
                <SingleNavItem>
                  <Popup
                    ref={popupRef}
                    trigger={
                      <NavLink to="#">
                        {" "}
                        {
                          languageData?.navigation_track_button[
                          webSettingData?.language
                          ]
                        }{" "}
                      </NavLink>
                    }
                    position="bottom center"
                  >
                    <TrackOrder />
                  </Popup>
                </SingleNavItem>

                {token ? (
                  <SingleNavItem>
                    <NavLink to="/tickets">
                      {userProfileDetails?.first_name || userProfileDetails?.last_name}
                    </NavLink>
                  </SingleNavItem>
                ) : (
                  <SingleNavItem>
                    <NavLink to="/login">
                      {
                        languageData?.navigation_login_button[
                        webSettingData?.language
                        ]
                      }
                    </NavLink>
                  </SingleNavItem>
                )}
              </NavigationUl>
            )}
          </NavigationWrapper>
        </HeaderBottomWrapper>
      </Container>
    </Wrapper>
  );
};

export default HeaderBottom;
