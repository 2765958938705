import styled from "styled-components";
import Button from "../../../bootstrap/Button";
import NavItem from "../../../bootstrap/NavItem";
import Ul from "../../../bootstrap/Ul";

export const PaymentAndButton = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
  // margin-top: 20px;
  justify-content: flex-end;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;
export const PaymentUl = styled(Ul)`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  list-style: none;
  font-size: 16px;
`;
export const SinglePayment = styled(NavItem)`
  margin-right: 20px;
  display: flex;
  align-items: center;
  label {
    text-transform:capitalize;
    font-size:14px;
  }
  input[type='radio']:checked {
    accent-color: #2b3087;
  }
`;
export const PaymentInput = styled.input`
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 8px 0 0;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  @media (max-width: 576px) {
    // margin-top: 20px;
    justify-content: flex-start;
  }
`;
export const CancelButton = styled(Button)`
  margin-right: 10px;
  text-transform: capitalize;
`;
export const PaymentButton = styled(Button)`
  text-transform: capitalize;
  ${(props) => ` 
background: ${props.btnbgcolor};
transition: 0.4s;
color: ${props.btntextcolor};
&&:hover {
  background: ${props.btnbghvcolor};
}
`}
`;
export const Label = styled.label`
  cursor: pointer;
`;
export const PaymentMethod = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: left;
  margin-top: 30px;
  label {
    text-transform: capitalize;
  }
`;

export const BookTicketButton = styled.button`
  display: inline-block;
  text-transform: capitalize;
  border-radius: 0.25rem;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
  width:20%;
  font-weight:700;
  font-size:12px;
  ${(props) => ` 
  background: ${props.btnbgcolor};
  color: ${props.btntextcolor};
  transition: 0.4s;
  &&:hover {
    background: ${props.btnbghvcolor};
  }
  @media (max-width:768px){
    width:100%;
  }
`}
`;