import styled from "styled-components";

export const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 100;
`;

export const StyledModalContainer = styled.div`
  overflow:hidden;
  padding: 1.5rem;
  border-radius: 9px;
  background: var(--default-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 200;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => (props.width ? props.width : "320px")};
  @media (max-width: 768px) {
    width: 250px;
    padding: ${(props) => (props.padding ? props.padding : "1.5rem")};
  }
`;
