export const setUserProfileDetails = (payload) => {
    return {
      type: "SET_USER_PROFILE_DETAILS",
      payload,
    };
  };

  export const setUserNewProfileDetails = (payload) => {
    return {
      type: "SET_USER_NEW_PROFILE_DETAILS",
      payload,
    };
  };